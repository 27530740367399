import React, { Component } from 'react';
import stopImage from '../../img/stop.png'
import leftArrow from '../../img/left-white-arrow.svg';
import './NotFound.scss';
import Link from '../Link/Link';

class NotFound extends Component {
    render() {
        return (
            <div className='container not-found'>
                <div className='grid'>
                    <div className='left'>
                        <h1>404</h1>
                        <h3>Whoops, wrong stop!</h3>
                        <p>The page you're seeking may have been renamed, removed, or may have never existed along this route.</p>
                        <Link title="Log In" to="/">                          
                            <a className='tripper-button'>
                                <img src={leftArrow} width={10} height={10} alt="left arrow icon"/>
                                <span>Back To Home</span>
                            </a></Link>

                    </div>
                    <div className='right'>
                        <img src={stopImage} height={330} width={435} alt="stop icon"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default NotFound;
