import React from 'react';
import config from '../../config';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import closeIcon from '../../img/close.svg';
import closeBtn from '../../img/close-btn.svg';

class TripFilters extends React.Component {

  handleDateChangeRaw(e) {
    e.preventDefault();
    return;
  }

  isMobile() {
    return window.$(window).width() < 1023;
  }

  render() {
    return (
      <form className="container-fluid align-items-center trip-filters mobileForm" 
      onSubmit={this.props.getSchedules.bind(this)}
      
      >
        <div className='row mobileGrid'>
            {/* <div className="roundtrip">
              <input className="tripType" id="oneway" type="radio" name="trip" checked={!this.props.isRoundTripSelected}  onChange={()=>(this.props.selectTripType({isRoundTripSelected: false}))}/>
              <label htmlFor="oneway">One Way</label>
            </div>
            <div className="roundtrip">
              <input className="tripType" type="radio" name="trip" value="roundtrip" checked={this.props.isRoundTripSelected} onChange={()=>(this.props.selectTripType({isRoundTripSelected: true}))}/>
              <label htmlFor="roundtrip">Round Trip</label>
            </div> */}

            {/* <div className="roundtrip">
                <select className="custom-select"value={this.props.isRoundTripSelected} onChange={()=>{this.props.selectTripType({isRoundTripSelected: !this.props.isRoundTripSelected})}}>
                  <option value={false}>One Way</option>
                  <option value={true}>Round Trip</option>
                </select>
            </div> */}
            <div className='radioBtns'>
              {
                  ["One Way","Round Trip"].map((option,index)=>{
                      return <label onClick={(e)=>{
                        if(index!=this.props.isRoundTripSelected){
                          this.props.selectTripType({isRoundTripSelected: !this.props.isRoundTripSelected})

                          this.props.hideTrips()
                        }
                      }} className={`${this.props.isRoundTripSelected==index && "activeLabel"} mylabel`} key={index}>
                          <div  className={`radioBorder ${this.props.isRoundTripSelected==index && "activeRadioBorder"}`}>
                              <div className={"radioBtn"}/>
                          </div>
                          {option}
                    </label>
                  })
              }
            </div>

            <div className='col-lg-2 col-md-2 col-sm-12'>
            {/* <div className="travel-form--label">Passengers</div> */}
            <div className="chooseNoOfSeats">
              <select onChange={this.props.handleUserInput.bind(this)} name="seats" value={this.props.data.seats} className="custom-select num-of-seats" id="inlineFormInputGroup">
                <option disabled selected>Number of Seats</option>
                <option value="1">1 Seat</option>
                <option value="2">2 Seats</option>
                <option value="3">3 Seats</option>
                <option value="4">4 Seats</option>
                <option value="5">5 Seats</option>
                <option value="6">6 Seats</option>
                <option value="7">7 Seats</option>
                <option value="8">8 Seats</option>
                <option value="9">9 Seats</option>
                <option value="10">10 Seats</option>
                <option value="11">11 Seats</option>
                <option value="12">12 Seats</option>
                <option value="13">13 Seats</option>
                <option value="14">14 Seats</option>
                <option value="15">15 Seats</option>
                <option value="16">16 Seats</option>
                <option value="17">17 Seats</option>
                <option value="18">18 Seats</option>
                <option value="19">19 Seats</option>
                <option value="20">20 Seats</option>
                <option value="21">21 Seats</option>
                <option value="22">22 Seats</option>
                <option value="23">23 Seats</option>
                <option value="24">24 Seats</option>
                <option value="25">25 Seats</option>
                <option value="26">26 Seats</option>
                <option value="27">27 Seats</option>
                <option value="28">28 Seats</option>
                <option value="29">29 Seats</option>
                <option value="30">30 Seats</option>
                <option value="31">31 Seats</option>
                <option value="32">32 Seats</option>
                <option value="33">33 Seats</option>
                <option value="34">34 Seats</option>
                <option value="35">35 Seats</option>
                <option value="36">36 Seats</option>
                <option value="37">37 Seats</option>
                <option value="38">38 Seats</option>
                <option value="39">39 Seats</option>
                <option value="40">40 Seats</option>
              </select>
            </div>
          </div>

        </div>
        <div className='labelRD'>Depart</div>
        <div className="grid">
          <div className="departingCity">
            {/* <div className="travel-form--label">From</div> */}
            <div className="input-group">
              <select disabled={this.props.data.loading} value={this.props.data.departCity} name='departCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select depart-city" id="inlineFormInputGroup">
                <option value=''>From</option>
                {config.cities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="arrivingCity">
            {/* <div className="travel-form--label">To</div> */}
            <div className="input-group">
              <select value={this.props.data.arriveCity}  disabled={!this.props.data.departCity || this.props.data.loading} name='arriveCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select arrive-city" id="inlineFormInputGroup">
                <option value=''>To</option>
                {this.props.data.arriveCities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='date-trip'>
              {/* <div className="travel-form--label">Leaving</div> */}
              <div className="trip-date-picker-wrap">
                <DatePicker disabled={this.props.data.loading} placeholderText='Leaving' readOnly={true} onChangeRaw={this.handleDateChangeRaw.bind(this)} withPortal={this.isMobile()} className="form-control arrive-date" minDate={moment()} dateFormat="M/D/YY" id="example-datepicker" selected={this.props.data.departDate} fixedHeight={true} onChange={this.props.handleDepartDateChange.bind(this)} />
              </div>
          </div>

        </div>

        <br/>
        
        { this.props.isRoundTripSelected && <div className='return-row'>
           <p className='labelRD'>Return</p> 
           <button className="returnBtn" onClick={(e)=>{
                        e.stopPropagation()
                        this.props.selectTripType({isRoundTripSelected:false})

                        // const oneway = tripTypes.filter((trip)=>trip.value === "oneway")[0];
                        // handleOptionSelect(undefined,"tripType",oneway)
                    }}> 
                        <div className="circularBtn">
                      
                          <img src={closeIcon} height="8px" width="8px" alt='close icon'/>

                        </div>
                        remove
                    </button>
          </div>
        }

        { this.props.isRoundTripSelected && <div className="grid">
          <div className="departingCity">
            {/* <div className="travel-form--label">From</div> */}
            <div className="input-group">
              <select disabled={!this.props.data.departCity} value={this.props.data.returnDepartCity} name='returnDepartCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select depart-city" id="inlineFormInputGroup">
                <option value=''>From</option>
                {this.props.data.arriveCities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className="arrivingCity">
            {/* <div className="travel-form--label">To</div> */}
            <div className="input-group">
              <select value={this.props.data.returnArriveCity} disabled={!this.props.data.returnDepartCity} name='returnArriveCity' onChange={this.props.handleUserInput.bind(this)} className="custom-select arrive-city" id="inlineFormInputGroup">
                <option value=''>To</option>
                {this.props.data.returnArriveCities.map((stop, index) => {
                  return (
                    <option key={index} value={stop.value}>{stop.label}</option>
                  )
                })}
              </select>
            </div>
          </div>
          <div className='date-trip'>
              {/* {this.props.data.returnDate && <div className="travel-form--label"><span>Remove Return <i onClick={this.props.removeReturn.bind(this)} className="fa fa-times-circle"></i></span></div>} */}
              <div className="trip-date-picker-wrap">
                <DatePicker placeholderText='Returning' readOnly={true} onChangeRaw={this.handleDateChangeRaw.bind(this)}  withPortal={this.isMobile()} className="form-control return-date" minDate={this.props.data.departDate} dateFormat="M/D/YY" id="example-datepicker" selected={this.props.data.returnDate} fixedHeight={true} onChange={this.props.handleReturnDateChange.bind(this)} />
              </div>
          </div>

          {/* <div className="col-lg-2 col-md-6 col-sm-6 col-6"> */}
              {/* {this.props.data.returnDate && <div className="travel-form--label"><span>Remove Return <i onClick={this.props.removeReturn.bind(this)} className="fa fa-times-circle"></i></span></div>}
              <div className="trip-date-picker-wrap">
                <DatePicker placeholderText='Returning' readOnly={true} onChangeRaw={this.handleDateChangeRaw.bind(this)}  withPortal={this.isMobile()} className="form-control return-date" minDate={this.props.data.departDate} dateFormat="M/D/YY" id="example-datepicker" selected={this.props.data.returnDate} fixedHeight={true} onChange={this.props.handleReturnDateChange.bind(this)} />
              </div>
              <small>Optional</small> */}
          {/* </div> */}

        </div>
      }
      {this.props.isRoundTripSelected && <br/>}
      <div className='search'>
        <button
          type="submit"
          disabled={this.props.data.loading||!this.props.data.departCity||!this.props.data.arriveCity||!this.props.data.departDate||(!this.props.data.returnDate && this.props.isRoundTripSelected)||this.props.data.seats===""}
        >SEARCH</button>

      </div>
      <div className='close-btn'>
        <button
          type="submit"
          onClick={(e)=>{
            e.preventDefault()
            
            this.props.setTripFilterCollapsed(true)
          }}
          disabled={this.props.data.loading||!this.props.data.departCity||!this.props.data.arriveCity||!this.props.data.departDate||(!this.props.data.returnDate && this.props.isRoundTripSelected)||this.props.data.seats===""}
        >
          <img src={closeBtn} height="30px" width="30px" alt='close icon'/>
          <p>Close</p></button>

      </div>
      </form>
    )
  }
}

export default TripFilters;
