import React from 'react';
import routeIcon from '../../img/route-icon.svg';
import arrowSwitchIcon from '../../img/arrow-switch-icon.svg';
import dash from '../../img/dash.svg';
import seatIcon from '../../img/seat-icon.svg';




class CollapsedTripFilters extends React.Component {

    formatDate(date) {
        var formatedDate = ''
        try{
            formatedDate =  date.format("ddd, MMM D, YYYY");
        }catch(e){
        }finally{
            return formatedDate
        }
    }

    render() {
    return <div className='callapedLayout'>
        <div className='locations'>
            <div className='location'>
                <p className='dateText'>{this.formatDate(this.props.data.departDate)}</p>
                <div className='route'>
                    <p>{this.props.data.departCity}</p>
                    <img src={routeIcon} width="11px" height="10px" alt='route icon' />
                    <p>{this.props.data.arriveCity}</p>
                </div>
            </div>
            {
                this.props.data.isRoundTripSelected === true && <img src={arrowSwitchIcon} width="24px" height="24px" alt='switch icon' />
            }
            {
                this.props.data.isRoundTripSelected === true && <div className='location'>
                <p className='dateText'>{this.formatDate(this.props.data.returnDate)}</p>
                <div className='route'>
                    <p>{this.props.data.returnDepartCity}</p>
                    <img src={routeIcon} width="11px" height="10px" alt='route icon' />
                    <p>{this.props.data.returnArriveCity}</p>
                </div>
            </div>
            }
        </div>
        <div className='details'>
            <img src={dash} width="36px" height="40px" alt='dash' />
            <p className='tripTypeText'>{this.props.data.isRoundTripSelected?"Round Trip":"One Way"}</p>
            <img src={dash} width="36px" height="40px" alt='dash icon' />
            <p className="tripTypeText"><img src={seatIcon} height={"16px"} width={"16px"} alt='seat icon' /> {this.props.data.seats} {this.props.data.seats == 1 ?"Seat":"Seats"}</p>
            <div className='editTripBtn'>
                <button
                    onClick={(e)=>{
                        e.preventDefault();
                        this.props.setTripFilterCollapsed(false)
                    }}>
                        Edit Trip
                </button>
            </div>
        </div>
    </div>
    }
}

export default CollapsedTripFilters;
