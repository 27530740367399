// home.js
import React from 'react';
import moment from 'moment';
import ReservationService from '../services/reservation';
import _ from 'lodash';
import config from '../config';
import confirmIcon from '../img/confirm_icon.png';
import departBusIcon from '../img/depart_bus.svg';
import returnBusIcon from '../img/return_bus.svg';
import rightArrow from '../img/rightOrangeArrow.svg';
import rightGrayArrow from '../img/gray-triangle-right.svg';
import Link from './Link/Link';

class TicketConfirmed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      departCity: '',
      arriveCity: '',
      returnDepartCity: '',
      returnArriveCity: '',
      departDate: moment().startOf('day'),
      returnDate: moment().startOf('day'),
      departTime: '12:00:00',
      arriveTime: '12:00:00',
      returnTime: '12:00:00',
      returnArriveTime: '12:00:00',
      seats: '',
      departTripCode: null,
      returnTripCode: null,
      confirmationCode: null,
      cities: config.cities
    };
  }

  getStopLabel(stop) {
    return _.find(this.state.cities, {value: stop}).label;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, {value: stop}).address;
  }

  componentDidMount() {
    window.$("html, body").animate({ scrollTop: 0 }, 'fast');
    let obj = {};
    if(this.props.location.query.departCity) {
      obj.departCity = this.props.location.query.departCity;
    }
    if(this.props.location.query.arriveCity) {
      obj.arriveCity = this.props.location.query.arriveCity;
    }
    if(this.props.location.query.returnDepartCity) {
      obj.returnDepartCity = this.props.location.query.returnDepartCity;
    }
    if(this.props.location.query.returnArriveCity) {
      obj.returnArriveCity = this.props.location.query.returnArriveCity;
    }
    if(this.props.location.query.departStop) {
      obj.depart_stop = this.props.location.query.departStop;
    }
    if(this.props.location.query.arriveStop) {
      obj.arrive_stop = this.props.location.query.arriveStop;
    }
    if(this.props.location.query.departStop1) {
      obj.depart_stop1 = this.props.location.query.departStop1;
    }
    if(this.props.location.query.arriveStop1) {
      obj.arrive_stop1 = this.props.location.query.arriveStop1;
    }
    if(this.props.location.query.departDate) {
      obj.departDate = this.props.location.query.departDate;
    }
    if(this.props.location.query.returnDate) {
      obj.returnDate = this.props.location.query.returnDate;
    }
    if(this.props.location.query.departTime) {
      obj.departTime = this.props.location.query.departTime;
    }
    if(this.props.location.query.returnTime) {
      obj.returnTime = this.props.location.query.returnTime;
    }

    if(this.props.location.query.departTime) {
      obj.arriveTime = this.props.location.query.arriveTime;
    }
    if(this.props.location.query.returnTime) {
      obj.returnArriveTime = this.props.location.query.returnArriveTime;
    }

    if(this.props.location.query.departTime) {
      obj.departTime = this.props.location.query.departTime;
    }
    if(this.props.location.query.returnTime) {
      obj.returnTime = this.props.location.query.returnTime;
    }
    if(this.props.location.query.seats) {
      obj.seats = parseInt(this.props.location.query.seats);
    }
    if(this.props.location.query.departTripCode) {
      obj.departTripCode = this.props.location.query.departTripCode;
    }
    if(this.props.location.query.returnTripCode) {
      obj.returnTripCode = this.props.location.query.returnTripCode;
    }
    if(this.props.location.query.confirmationCode) {
      obj.confirmationCode = this.props.location.query.confirmationCode;
    }
    if(Object.keys(obj).length > 0) {
      this.setState(obj);
    }
  }

  createBlob(data, filename){
    var blob = new Blob([data], {type: 'application/pdf'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      var blobURL = window.URL.createObjectURL(blob);
      var tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      if (typeof tempLink.download === 'undefined') {
          tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  download(code) {
    ReservationService.download(code)
    .then((result) => {
      this.createBlob(result.body, 'ticket.pdf');
    })
    .catch((err) => {
      this.setState({loading: false, error: err.toString()});
    });
  }

  render() {
  	return (
      <div>
        {/* <div className="tickets-confirmed-banner">Booking Confirmation</div> */}
        <div className="editTrip addGiftWrapper tickets-confirmed">
          <div className="content-body">
            {/* <div className="header-content text-center">
                  <div className="form-group"><img src={confirmIcon} alt="" /></div>
                <div className="row justify-content-center">
                  <div className="col-md-8">
                    <h4>Your trip has been confirmed</h4>
                    {this.state.confirmationCode && <p>We have created an account with the email that you entered and email you the password reset instructions, sign in to your account to view and manage your reservations.</p>}
                    <p>You will recieve your itinerary shortly in your email. You can return to our site at anytime and
                      check the status of your trip. If you need to make any changes to your reservation please be
                      ready to use your ticket code number
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="card ticket-confirmed-card">
                <div className="card-header">
                  <h3>Your Ticket Purchase Was Successful</h3>
                </div>
                <div className='heading'>
                      <p>Order Details</p>
                </div>
                <div className="clearfix">
                  {this.state.departTripCode && <div className="trip-row">
                    <div className="grid">
                      <div className='ticketInfo'>
                        <div className='grid sub-grid-depart'>
                          <div>Seat/s</div>
                          <span>{this.state.seats}</span>
                        </div>
                        <div className='grid sub-grid-depart'>
                          <div>Ticket ID</div>
                          <span>{config.formatReservationNumber(this.state.departTripCode)}</span>
                        </div>
                      </div>
                    <div className='otherDetails'>
                    <div className='grid sub-grid-depart'>
                        <div><img src={departBusIcon} height="20px" width="20px" alt="icon for bus departing"/></div>
                        <div className='title'>Departing trip</div>
                        {/* <div><span>{this.getStopLabel(this.state.departCity)} to {this.getStopLabel(this.state.arriveCity)}</span></div> */}
                      </div>
                      <div className='grid sub-grid-depart'>
                        <div></div>
                        <div>
                          <div className='grid-stops'>
                            <span className='cityDetail'>
                              {this.getStopLabel(this.state.departCity)}
                            </span>
                            <span>
                              <img src={rightArrow} width="20px" height={"20px"} alt="right arrow icon"/>
                            </span>
                           <span className='cityDetail'>
                            {this.getStopLabel(this.state.arriveCity)}
                           </span>
                           </div>
                        </div>
                      </div>
                      <div className='grid sub-grid-depart-3'>
                        <div></div>
                        <div className='grid time'>
                          {moment(this.state.departDate).format('MMM DD')}
                          <span className="bold">{moment(this.state.departTime, 'HH:mm:ss').format('hh:mm a')}</span>
                          <img src={rightGrayArrow} width="10px" height="10px" alt="right arrow icon"/>
                          <span className="bold">{moment(this.state.arriveTime, 'HH:mm:ss').format('hh:mm a')}</span>
                        </div>
                      </div>
                      {/* <div className='grid sub-grid-depart'>
                        <label>Pick up</label>
                        <span>{this.state.depart_stop}</span>
                      </div>
                      <div className='grid sub-grid-depart'>
                        <label>Drop off</label>
                        <span>{this.state.arrive_stop}</span>
                      </div> */}
                    </div>
                    <div className="download-btn">
                      <button type="button" className="btn btn-blue" onClick={this.download.bind(this, this.state.departTripCode)}>Download PDF</button>
                    </div>
                    </div>
                  </div>}
                  {
                    this.state.returnTripCode &&
                    <div className="trip-row first-trip">
                      <div className="grid">


                    <div className='ticketInfo'>
                        <div className='grid sub-grid-depart'>
                          <div>Seat/s</div>
                          <span>{this.state.seats}</span>
                        </div>
                        <div className='grid sub-grid-depart'>
                          <div>Ticket ID</div>
                          <span>{config.formatReservationNumber(this.state.returnTripCode)}</span>
                        </div>
                      </div>

                      <div className='otherDetails'>

                        <div className='grid sub-grid-depart'>
                          <div><img src={returnBusIcon} height="20px" width="20px" alt="returning bus icon"/></div>
                          <div className='title'>Returning trip</div>
                          {/* <span>{this.getStopLabel(this.state.returnDepartCity)} to {this.getStopLabel(this.state.returnArriveCity)}</span> */}
                        </div>

                        <div className='grid sub-grid-depart'>
                        <div></div>
                        <div>
                          <div className='grid-stops'>{this.getStopLabel(this.state.returnDepartCity)}
                            <span><img src={rightArrow} width="20px" height={"20px"} alt="right arrow icon"/></span>
                           {this.getStopLabel(this.state.returnArriveCity)}</div>
                        </div>
                      </div>


                        <div className='grid sub-grid-depart-3'>
                          <div></div>
                          <div className='grid time'>
                            {moment(this.state.returnDate).format('MMM DD')}
                            <span className="bold">{moment(this.state.returnTime, 'HH:mm:ss').format('hh:mm a')}</span>
                            <img src={rightGrayArrow} width="10px" height="10px" alt="right arrow icon"/>
                            <span className="bold">{moment(this.state.returnArriveTime, 'HH:mm:ss').format('hh:mm a')}</span>
                          </div>
                      </div>
                    </div>

                      <div className="download-btn">
                        <button type="button" className="btn btn-blue" onClick={this.download.bind(this, this.state.returnTripCode)}>Download PDF</button>
                      </div>
                      </div>

                    </div>
                  }
                </div>
              </div>
              <div className="text-center be-sure-join">Be Sure to join our <span className="blue-text"><Link target="_blank" to="/rewards" externalLink={true}>Loyalty Rewards Program</Link></span> to earn free travel!</div>
          </div>
        </div>
      </div>
  	)
  }
}

export default TicketConfirmed;
