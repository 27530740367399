import React from 'react';
import UserStore from '../../stores/UserStore';
import Auth from '../../services/auth';
import Order from '../../services/order';
import InputMask from 'react-input-mask';
import numeral from 'numeral';
import moment from 'moment';
import _ from 'lodash';
import config from '../../config';
import { setUser } from '../../actions/UserActions';
import {history as browserHistory} from '../../helpers/history';
import eliteIcon from '../../img/eliteIcon.svg';
import smsIcon from '../../img/sms.png';
import departBusIcon from '../../img/depart_bus.svg';
import returnBusIcon from '../../img/return_bus.svg';
import personIcon from '../../img/new_person_icon.svg';
import fuelFeeIcon from '../../img/fuel_fee.svg';
import reservationFeeIcon from '../../img/reservation_fee.svg';
import Modal from '../Modal/modal';
import ResetPassword from './passwordReset';
import { eventTracker, purchaseEvent } from '../../helpers/ga_events';
import Link from '../Link/Link';
import showToast from '../../helpers/toast';
import Itinerary from '../Cards/Itinerary/Itinerary';
import Login from '../Cards/Login/Login';
import routeIcon from '../../img/route-icon.svg';
import ReservationStore from '../../stores/ReservationStore';
import Checkbox from '../inputs/checkbox';



// import card1 from '../../img/cardLogos/card1.svg';
// import card2 from '../../img/cardLogos/card2.svg';
// import card3 from '../../img/cardLogos/card3.svg';
// import card4 from '../../img/cardLogos/card4.svg';
// import card5 from '../../img/cardLogos/card5.svg';
// import card6 from '../../img/cardLogos/card6.svg';
// import card7 from '../../img/cardLogos/card7.svg';
// import card8 from '../../img/cardLogos/card8.svg';
// import card9 from '../../img/cardLogos/card9.svg';


// const cardLogos = [card1, card2, card3, card4, card5, card6, card7, card8, card9];

const siteurl = process.env.NODE_ENV === 'development' ? 'https://tripperbus.dev' : 'https://booking.tripperbus.com';

class ConfirmTrip extends React.Component {
  constructor(props) {
    super(props);
    this.onUserChange = this.onUserChange.bind(this);
    this.emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    this.state = {
      loading: false,
      isMarketingChecked: true,
      isPromoCodeAllowed:true,
      cities: config.cities,
      paymentMethod: 'cc',
      user: UserStore.getUser() ? JSON.parse(JSON.stringify(UserStore.getUser())) : {
        firstName: '',
        lastName: '',
        email: '',
        // confirmEmail: '',
        password: '',
        confirmPassword: '',
        phone: ''
      },
      auth: {
        userName: '',
        secret: ''
      },
      card: {
        cardHolder: '',
        number: '',
        month: '',
        year: '',
        cvv: '',
        zip: ''
      },
      firstName: UserStore.getUser() ? UserStore.getUser().firstName : '',
      lastName: UserStore.getUser() ? UserStore.getUser().lastName : '',
      couponCode: '',
      giftCardCode: '',
      coupon: null,
      giftCard: null,
      credit: 0,
      payment: 0,
      vouchersDiscount: 0,
      vouchersUsed: 0,
      loginError: '',
      useGift: false,
      useCoupon: false,
      useCredit: false,
      useVoucher: false,
      months: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      years: [],
      acceptTerms: false,
      couponError: '',
      giftError: '',
      createAccount: false,
      discount: 0,
      reservationFee: 2.25,
      fuelSurcharge: 1.75,
      error: '',
      departTripCode: '',
      returnTripCode: '',
      confirmationCode: '',
      availableCredit: (UserStore.getUser() && UserStore.getUser().id ? UserStore.getUser().credit : 0),
      vouchersForFreeTicketDepart: config.vouchersForFreeTicket,
      vouchersForFreeTicketReturn: config.vouchersForFreeTicket,
      availableVouchers: (UserStore.getUser() && UserStore.getUser().id ? UserStore.getUser().vouchers : 0),
      voucherLegs: [],
      currentStep: 1,
      editNameMode: UserStore.getUser() &&  UserStore.getUser().firstName ? false : true,
      continueAsGuest: false,
      isLoggedIn: UserStore.getLoggedIn(),
    };
  }

  createPurchaseEventDetails(body, result) {
    const items = body.seats.map((seat, index)=>{
      return {
       item_id: result.returnTripCode ? `Seat${index}-${result.returnTripCode} `: `Seat${index}-${result.departTripCode}`,
       ...seat
      }
   })
   return {
     transaction_id: result.transactionId,
     value: body.payment || 0,
    //  creditsUsed: body.credit || 0,
    //  discount: body.discount || 0,
     currency: "USD",
    //  items,
    //  paymentMethod: body.paymentMethod,
    //  scheduleId: body.scheduleId,
    //  returnscheduleId: body.scheduleId1 || 'N/A',
    //  returnTripCode: result.returnTripCode || 'N/A',
    //  departTripCode: result.departTripCode || 'N/A'
   }

  }

  checkIsCustomerInfoDisabled() {

    if(!this.state.user.email || !this.state.user.phone || (this.state.createAccount && (!this.state.user.password || !this.state.user.confirmPassword)) ) {
      return true;
    }

    return false;
  }

  handleTermsAccept() {
    this.setState({ acceptTerms: !this.state.acceptTerms });
  }

  handleCreateAccount() {
    if(this.state.createAccount) {
      this.setState({password: '', confirmPassword: ''});
    }
    this.setState({ createAccount: !this.state.createAccount });
  }

  handleChange(e) {
    this.setState({[e.target.name] : e.target.value, submitted: false});
  }

  handleLoginBlur(e) {
    if(e.target.value && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['auth_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else {
      let error = {};
      error['auth_'+e.target.name+'_error'] = '';
      this.setState(error);
    }
  }

  handleLoginInput(e) {
    let auth = this.state.auth;
    auth[e.target.name] = e.target.value;
    this.setState({ auth: auth, loginError: '', submitted: false });
    let error = {};
    error['auth_'+e.target.name+'_error'] = '';
    this.setState(error);
  }


  calculatePayment() {
    let total = 0;
    let seats = this.props.data.seats;
    if (this.props.data.departTrip) {
      total += this.props.data.departTrip.price * seats;
    }
    if (this.props.data.returnTrip) {
      total += this.props.data.returnTrip.price * seats;
    }
    total = total + this.state.reservationFee + this.state.fuelSurcharge;
    if (this.state.useCoupon && this.state.coupon && this.state.coupon.type === '%' && ((total * (this.state.coupon.amount / 100)) < total)) {
      let couponDiscount = total * (this.state.coupon.amount / 100);
      this.setState({discount: Math.floor(couponDiscount*100)/100});
      total = total - (total * (this.state.coupon.amount / 100));
    }
    else if (this.state.useCoupon && this.state.coupon && this.state.coupon.type === '%' && ((total * (this.state.coupon.amount / 100)) >= total)) {
      this.setState({discount: total});
      total = 0;
    }
    else if (this.state.useCoupon && this.state.coupon && this.state.coupon.type === '$' && this.state.coupon.amount < total) {
      total = total - this.state.coupon.amount;
      this.setState({discount: this.state.coupon.amount});
    }
    else if (this.state.useCoupon && this.state.coupon && this.state.coupon.type === '$' && this.state.coupon.amount >= total) {
      this.setState({discount: total});
      total = 0;
    }
    else if (this.state.useGift && this.state.giftCard && this.state.giftCard.amount < total) {
      total = total - this.state.giftCard.amount;
      this.setState({discount: this.state.giftCard.amount});
    }
    else if (this.state.useGift && this.state.giftCard && this.state.giftCard.amount >= total) {
      this.setState({discount: total});
      total = 0;
    }
    else if (this.state.useCredit && this.state.credit) {
      total = total - this.state.credit;
      this.setState({discount: this.state.credit});
    }
    else if (this.state.useVoucher && this.state.vouchersDiscount) {
      total = total - this.state.vouchersDiscount;
      this.setState({discount: this.state.vouchersDiscount});
    }
    else {
      this.setState({discount: 0});
    }
    this.setState({ payment: total });
  }

  closeModal() {
    window.$('#login').modal('hide');
    window.$('.modal-backdrop').remove();
    window.$('body').removeClass('modal-open');
  }
  
  showForgotPasswordModal() {
    this.closeModal();
    window.$('#myModal').modal('show');
    
  }

  login(e) {
    e.preventDefault();
    if (!this.state.auth.userName || !this.state.auth.secret) {
      let error = {};
      if (!this.state.auth.userName) {
        error['auth_userName_error'] = 'Required';
      }
      if (!this.state.auth.secret) {
        error['auth_secret_error'] = 'Required';
      }
      this.setState(error);
      return;
    }
    this.setState(function () {
      return { loginError: '', loading: true };
    });
    Auth.login(this.state.auth.userName, this.state.auth.secret)
      .then(() => {
        this.setState({loading: false, currentStep: 2});
        this.closeModal()
      })
      .catch((err) => {
        showToast('error', err);
        this.setState(function () {
          return { loginError: "", loading: false };
        });
      });
  }

  onUserChange() {
    let user = UserStore.getUser();
    if (user) {
      let credit = user.credit;
      let vouchers = user.vouchers;
      if(this.props.data.reservationNumber && this.props.data.amount) {
        credit += parseInt(this.props.data.amount);
      }
      if(this.props.data.reservationNumber && this.props.data.points) {
        vouchers += parseInt(this.props.data.points);
      }
      this.setState({user: user, availableCredit: credit, availableVouchers: vouchers, isLoggedIn: UserStore.getLoggedIn()}, () => {
        //this.getEarnedPoints();
        this.calculatePayment();
      });
    }
    else {
      this.setState({
        user: {
          firstName: '',
          lastName: '',
          email: '',
          // confirmEmail: '',
          password: '',
          confirmPassword: '',
          phone: ''
        }, auth: {
          userName: '',
          secret: ''
        },
        firstName: '',
        lastName: '',
        useCoupon: false,
        coupon: null,
        useCredit: false,
        credit: 0,
        useVoucher: false,
        vouchersUsed: 0,
        vouchersDiscount: 0,
        availableVouchers: (this.props.data.points || 0),
        availableCredit: (this.props.data.amount || 0)
      }, () => {
        this.calculatePayment();
      });
    }
  }

  getStopCode(city) {
    return _.find(this.state.cities, {value: city}).code;
  }

  // getEarnedPoints() {
  //   User.getEarnedPoints(moment(this.props.data.departDate).format('YYYY-MM-DD'), this.props.data.departTrip.schedule_bus)
  //   .then((data) => {
  //     console.log(data)
  //     this.setState({availableVouchers: this.state.availableVouchers + data.points, voucherLegs: data.legs});
  //   })
  //   .catch(err => {
  //     console.log(err);
  //   })
  // }

  checkEliteOnly() {
    if(this.props.data.returnTrip) {
      return this.props.data.departTrip.bus_type.type === 'Elite' && this.props.data.returnTrip.bus_type.type === 'Elite';
    }
    else {
      return this.props.data.departTrip.bus_type.type === 'Elite';
    }
  }

  checkElite() {
    if(this.props.data.returnTrip) {
      return this.props.data.departTrip.bus_type.type === 'Elite' || this.props.data.returnTrip.bus_type.type === 'Elite';
    }
    else {
      return this.props.data.departTrip.bus_type.type === 'Elite';
    }
  }

  componentDidMount() {
    window.$("html, body").animate({ scrollTop: 0 }, 'fast');
    UserStore.addChangeListener(this.onUserChange);
    if(this.props.data.departTrip.bus_type.type === 'Elite' || (this.props.data.busType === 'Elite' && this.props.data.oldSeats && this.props.data.points && (this.props.data.seats <= this.props.data.oldSeats))) {
      this.setState({vouchersForFreeTicketDepart: 12});
    }
    if(this.props.data.returnTrip && this.props.data.returnTrip.bus_type.type === 'Elite') {
      this.setState({vouchersForFreeTicketReturn: 12});
    }
    // if(this.state.user.id) {
    //   this.getEarnedPoints();
    // }
    let years = [];
    let start = new Date().getFullYear();
    let end = start + 11;
    for (let i = start; i < end; i++) {
      years.push(i.toString().substr(2));
    }
    this.setState({ years: years });
    if(this.props.data.reservationNumber) {
      this.setState({reservationFee: 0, fuelSurcharge: 0});
    }
    if(this.props.data.reservationNumber) {
      if(this.props.data.amount && parseInt(this.props.data.amount)) {
        let amount = parseInt(this.props.data.amount);
        if(this.state.user && this.state.user.id) {
          amount += this.state.user.credit;
        }
        this.setState({availableCredit: amount}, () => {
          let total = this.state.reservationFee;
          let seats = this.props.data.seats;
          if (this.props.data.departTrip) {
            total += this.props.data.departTrip.price * seats;
          }
          if (this.props.data.returnTrip) {
            total += this.props.data.returnTrip.price * seats;
          }
          this.setState({payment: total, discount: 0}, () => {
            this.applyCredit();
          });
        });
      }
      if(this.props.data.points && parseInt(this.props.data.points)) {
        let vouchers = parseInt(this.props.data.points);
        if(this.state.user && this.state.user.id) {
          vouchers += this.state.user.vouchers;
        }
        this.setState({availableVouchers: vouchers}, () => {
          let total = this.state.reservationFee;
          let seats = this.props.data.seats;
          if (this.props.data.departTrip) {
            total += this.props.data.departTrip.price * seats;
          }
          if (this.props.data.returnTrip) {
            total += this.props.data.returnTrip.price * seats;
          }
          this.setState({payment: total, discount: 0}, () => {
            if (this.pointsAllowed()) {
              this.applyPoints();
            }
          });
        });
      }
    }
    else {
      this.calculatePayment();
    }

    if(!UserStore.getLoggedIn()) {
      let guestReservation = ReservationStore.getReservation();
      if(guestReservation) {
        this.setState({guestUser: {firstName: guestReservation.user.firstName, lastName: guestReservation.user.lastName, email: guestReservation.user.email, phone: guestReservation.user.phone, password: '', confirmPassword: ''}});
      }
    }

    if(this.state.firstName && this.state.lastName) {
      this.setState({currentStep: 3});
    }
  }

  componentWillUnmount() {
    UserStore.removeChangeListener(this.onUserChange);
  }

  verifyCoupon() {
    this.setState({loading: true, couponError: ''});
    if (!this.state.user.id) {
      Order.verifyCoupon(null, this.state.couponCode, moment(this.props.data.departDate).format('YYYY-MM-DD'), this.checkElite())
        .then(result => {
          this.setState({coupon: result}, () => {
            this.calculatePayment();
          });
          this.setState({loading: false});
        })
        .catch(err => {
          showToast('error',  err.response.text);

          this.setState({loading: false, couponError:"", coupon: null}, () => {
            this.calculatePayment();
          });
        });
    }
    else {
      Order.verifyCoupon(this.state.user.id, this.state.couponCode, moment(this.props.data.departDate).format('YYYY-MM-DD'), this.checkElite())
        .then(result => {
          this.setState({coupon: result}, () => {
            this.calculatePayment();
          });
          this.setState({loading: false});
        })
        .catch(err => {
          
        showToast('error',  err.response.text);

          this.setState({loading: false, couponError:"", coupon: null}, () => {
            this.calculatePayment();
          });
        });
    }
  }

  verifyGiftCard() {
    this.setState({loading: true, giftError: ''});
    Order.verifyGiftCard(this.state.giftCardCode)
      .then(result => {
        
        this.setState({giftCard: result}, ()=> {
          this.calculatePayment();
        });
        this.setState({loading: false});
      })
      .catch(err => {
        
        showToast('error',  err.response.statusText);

        this.setState({loading: false, giftError: ""});
      });
  }

  getSeatsPrice() {
    let seats = this.props.data.seats;
    let data = [];
    let obj;
    let vouchers = this.state.vouchersUsed;
    let credits = 0;
    let coupons = this.state.coupon ? this.state.coupon.amount : 0;
    let gift = this.state.giftCard ? this.state.giftCard.amount : 0;
    let totalSeats = seats;
    let perSeatCredits = 0;
    let creditUsed = 0, paid = 0;
    if(this.props.data.returnTrip) {
      totalSeats = seats * 2;
    }
    let totalPrice = this.props.data.departTrip.price * seats;
    if(this.props.data.returnTrip) {
      totalPrice += this.props.data.returnTrip.price * seats;
    }
    if(this.state.useCredit && this.state.credit > totalPrice) {
      credits = totalPrice;
    }
    if(this.state.useCredit && this.state.credit <= totalPrice) {
      credits = this.state.credit;
    }
    if (this.props.data.departTrip) {
      for (let i = 0; i < seats; i++) {
        creditUsed = 0;
        obj = {
          price: this.props.data.departTrip.price,
          description: '1 seat - ' + this.props.data.departCity + ' to ' + this.props.data.arriveCity,
          paid: 0,
          vouchers: 0,
          credit: 0
        };
        if(this.state.useVoucher && vouchers >= this.state.vouchersForFreeTicketDepart) {
          obj.vouchers = this.state.vouchersForFreeTicketDepart;
          vouchers -= this.state.vouchersForFreeTicketDepart;
        }
        else if(this.state.useCredit && credits > 0) {
          creditUsed = (credits > this.props.data.departTrip.price) ? this.props.data.departTrip.price : credits;
          paid = this.props.data.departTrip.price - creditUsed;
          obj.credit = creditUsed;
          obj.paid = paid;
          credits -= creditUsed;
        }
        else if(this.state.useCoupon && coupons > 0) {
          creditUsed = (coupons > this.props.data.departTrip.price) ? this.props.data.departTrip.price : coupons;
          paid = this.props.data.departTrip.price - creditUsed;
          obj.paid = paid;
          if(this.state.coupon.complimentary_user) {
            obj.credit = creditUsed;
          }
          coupons -= creditUsed;
        }
        else if(this.state.useGift && gift > 0) {
          creditUsed = (gift > this.props.data.departTrip.price) ? this.props.data.departTrip.price : gift;
          paid = this.props.data.departTrip.price - creditUsed;
          obj.credit = creditUsed;
          obj.paid = paid;
          gift -= creditUsed;
        }
        else {
          obj.paid = this.props.data.departTrip.price;
        }
        data.push(obj);
      }
    }
    if (this.props.data.returnTrip) {
      for (let i = 0; i < seats; i++) {
        obj = {
          return: true,
          price: this.props.data.returnTrip.price,
          description: '1 seat - ' + this.props.data.returnDepartCity + ' to ' + this.props.data.returnArriveCity,
          paid: 0,
          vouchers: 0,
          credit: 0
        };
        if(this.state.useVoucher && vouchers >= this.state.vouchersForFreeTicketReturn) {
          obj.vouchers = this.state.vouchersForFreeTicketReturn;
          vouchers -= this.state.vouchersForFreeTicketReturn;
        }
        else if(this.state.useCredit && credits > 0) {
          creditUsed = (credits > this.props.data.returnTrip.price) ? this.props.data.returnTrip.price : credits;
          paid = this.props.data.returnTrip.price - creditUsed;
          obj.credit = creditUsed;
          obj.paid = paid;
          credits -= creditUsed;
        }
        else if(this.state.useCoupon && coupons > 0) {
          creditUsed = (coupons > this.props.data.returnTrip.price) ? this.props.data.returnTrip.price : coupons;
          paid = this.props.data.returnTrip.price - creditUsed;
          if(this.state.coupon.complimentary_user) {
            obj.credit = creditUsed;
          }
          obj.paid = paid;
          coupons -= creditUsed;
        }
        else if(this.state.useGift && gift > 0) {
          creditUsed = (gift > this.props.data.returnTrip.price) ? this.props.data.returnTrip.price : gift;
          paid = this.props.data.returnTrip.price - creditUsed;
          obj.credit = creditUsed;
          obj.paid = paid;
          gift -= creditUsed;
        }
        else {
          obj.paid = this.props.data.returnTrip.price;
        }
        data.push(obj);
      }
    }
    return data;
  }

  handleDiscounts(e) {
    if(this.props.data.reservationNumber){
      this.setState({isPromoCodeAllowed:false})
      return
    }
    else{
      this.setState({isPromoCodeAllowed:true})
    }
    let payment = this.state.payment + this.state.discount;
    this.setState({vouchersDiscount: 0, vouchersUsed: 0, credit: 0, payment: payment});
    if (e.target.name === 'useCoupon') {
      this.setState({ useCoupon: true, useCredit: false, useGift: false, useVoucher: false, coupon: null }, () => {
        this.calculatePayment();
      });
    }
    else if (e.target.name === 'useCredit') {
      this.setState({ useCoupon: false, useCredit: true, useGift: false, useVoucher: false, coupon: null });
      if(this.state.availableCredit >= (payment + this.state.discount)) {
        this.setState({credit: payment}, () => {
          this.calculatePayment();
        });
      }
      else {
        this.setState({credit: this.state.availableCredit}, () => {
          this.calculatePayment();
        });
      }
    }
    else if (e.target.name === 'useVoucher') {
      this.setState({ useCoupon: false, useCredit: false, useGift: false, useVoucher: true, coupon: null });
      let vouchers = this.state.availableVouchers;
      let vouchersUsed = 0;
      let discount = 0;
      for(let i = 0; i < this.props.data.seats; i++) {
        if (vouchers >= this.state.vouchersForFreeTicketDepart && discount < (this.state.payment - this.state.reservationFee + this.state.discount)) {
          if (this.props.data.busType === 'Elite' && this.props.data.oldSeats && this.props.data.points && i < (this.props.data.oldSeats)) {
            vouchersUsed = vouchersUsed + 12;
            vouchers = vouchers - 12;
          }
          else {
            vouchersUsed = vouchersUsed + this.state.vouchersForFreeTicketDepart;
            vouchers = vouchers - this.state.vouchersForFreeTicketDepart;
          }
          discount = discount + this.props.data.departTrip.price;
        }
      }
      if (this.props.data.returnDate && this.props.data.returnTrip) {
        for(let j = 0; j < this.props.data.seats; j++) {
          if (vouchers >= this.state.vouchersForFreeTicketReturn && discount < (this.state.payment - this.state.reservationFee + this.state.discount)) {
            vouchersUsed = vouchersUsed + this.state.vouchersForFreeTicketReturn;
            discount = discount + this.props.data.returnTrip.price;
            vouchers = vouchers - this.state.vouchersForFreeTicketReturn;
          }
        }
      }
      this.setState({vouchersDiscount: discount, vouchersUsed: vouchersUsed}, () => {
        this.calculatePayment();
      });
    }
    else if (e.target.name === 'useGift') {
      this.setState({ useCoupon: false, useCredit: false, useGift: true, useVoucher: false, coupon: null }, () => {
        this.calculatePayment();
      });
    }
  }

  createArrayOfNum() {
    let arr = [];
    for(let i = 1; i <= this.props.data.seats; i++) {
      arr.push(i);
    }
    return arr;
  }

  validateData(doNotUpdate) {
    // validation if user is logged in
    if (this.state.user.id) {
      let obj = {};
      if (!this.state.firstName) {
        obj['firstName_error'] = 'Required';
      }
      if (!this.state.lastName) {
        obj['lastName_error'] = 'Required';
      }
      if (!this.state.user.phone) {
        obj['user_phone_error'] = 'Required';
      }
      if (this.state.firstName && !doNotUpdate) {
        this.setState({firstName_error: ''});
      }
      if (this.state.lastName && !doNotUpdate) {
        this.setState({firstName_error: ''});
      }
      if (this.state.user.phone && !doNotUpdate) {
        this.setState({user_phone_error: ''});
      }
      if(this.state.payment) {
        for (let key in this.state.card) {
          if (!this.state.card[key]) {
            obj['card_'+key+'_error'] = 'Required';
          }
          else if(key === 'cvv' && (/\d{3}/.test(this.state.card.cvv) === false && /\d{4}/.test(this.state.card.cvv) === false)) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(this.state.card.number) === false && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{3}/.test(this.state.card.number) === false) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'cardHolder' && /^([A-Za-z\s.]{3,})$/.test(this.state.card.cardHolder) === false) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(this.refs[key].validity && !this.refs[key].validity.valid) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else {
            if(!doNotUpdate) {
              this.setState({['card_'+key+'_error']: ''});
            }
          }
        }
        if (Object.keys(obj).length > 0) {
          
          if(!doNotUpdate) {
            this.setState(obj);
          }
          return false;
        }
      }
    }
    //validation if user is a guest
    else {
      let obj = {};
      if (!this.state.firstName) {
        obj['firstName_error'] = 'Required';
      }
      if (!this.state.lastName) {
        obj['lastName_error'] = 'Required';
      }
      if (this.state.firstName && !doNotUpdate) {
        this.setState({firstName_error: ''});
      }
      if (this.state.lastName && !doNotUpdate) {
        this.setState({firstName_error: ''});
      }
      //validate payment as guest
      if(this.state.payment) {
        for (let key in this.state.card) {
          if (!this.state.card[key]) {
            obj['card_'+key+'_error'] = 'Required';
          }
          else if(key === 'cvv' && (/\d{3}/.test(this.state.card.cvv) === false && /\d{4}/.test(this.state.card.cvv) === false)) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(this.state.card.number) === false && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{3}/.test(this.state.card.number) === false) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(key === 'cardHolder' && /^([A-Za-z\s.]{3,})$/.test(this.state.card.cardHolder) === false) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else if(this.refs[key].validity && !this.refs[key].validity.valid) {
            obj['card_'+key+'_error'] = 'Invalid';
          }
          else {
            if(!doNotUpdate) {
              this.setState({['card_'+key+'_error']: ''});
            }
          }
        }
      }
      if(!this.props.data.reservationNumber) {
        for (let field in this.state.user) {
          if (!this.state.user[field]) {
            if(field === 'confirmPassword' || field === 'password') {
              if(this.state.createAccount) {
                obj['user_'+field+'_error'] = 'Required';
              }
              else {
                if(!doNotUpdate) {
                  this.setState({['user_'+field+'_error']: ''});
                }
              }
            }
            else if(field === 'email'|| field === 'phone') {
              obj['user_'+field+'_error'] = 'Required';
            }
          }
          else if(field === 'email' && this.state.user.email && this.emailRegex.test(this.state.user.email) === false) {
            obj['user_email_error'] = 'Invalid';
          }
          // else if(field === 'confirmEmail' && this.state.user.email && (this.state.user.email.toLowerCase() !== this.state.user.confirmEmail.toLowerCase())) {
          //   obj['user_confirmEmail_error'] = 'Email and confirm email should be same';
          // }
          // else if(field === 'email' && this.state.user.confirmEmail && (this.state.user.email.toLowerCase() !== this.state.user.confirmEmail.toLowerCase())) {
          //   obj['user_confirmEmail_error'] = 'Email and confirm email should be same';
          // }
          else if(this.state.createAccount && field === 'confirmPassword' && this.state.user.password && (this.state.user.password !== this.state.user.confirmPassword)) {
            obj['user_confirmEmail_error'] = 'Password and confirm password should be same';
          }
          else if(this.state.createAccount && field === 'password' && this.state.user.confirmPassword && (this.state.user.password !== this.state.user.confirmPassword)) {
            obj['user_confirmEmail_error'] = 'Password and confirm password should be same';
          }
          else if(field === 'phone' && this.state.user.phone && /\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(this.state.user.phone) === false) {
            obj['user_'+field+'_error'] = 'Invalid';
          }
          else if(this.refs && this.refs[field] && this.refs[field].validity && !this.refs[field].validity.valid) {
            obj['user_'+field+'_error'] = 'Invalid';
          }
          else {
            if(!doNotUpdate) {
              this.setState({['user_'+field+'_error']: ''});
            }
          }
        }
      }
      if (Object.keys(obj).length > 0) {
        
        if(!doNotUpdate) {
          this.setState(obj);
        }
        return false;
      }
    }
    return true;
  }

  changePaymentMethod(val, e) {
    
    this.setState({paymentMethod: val});
  }

  bookSeats(e) {
    e.preventDefault();
    this.setState({submitted: true});
    let isValid = this.validateData();
    if(!this.state.acceptTerms || !isValid) {
      return;
    }
    this.setState({loading: true, error: ''});
    let body = {
      seats: this.getSeatsPrice(),
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      phone: this.state.user.phone,
      discount: this.state.discount,
      roundTrip: (this.props.data.returnTrip ? true : false),
      departStops: this.props.data.departTrip.stops,
      departCity: this.props.data.departCity,
      departCityCode: this.getStopCode(this.props.data.departCity),
      arriveCityCode: this.getStopCode(this.props.data.arriveCity),
      arriveCity: this.props.data.arriveCity,
      scheduleId: this.props.data.departTrip.scheduleId,
      depart_stop: this.props.data.depart_stop.id,
      arrive_stop: this.props.data.arrive_stop.id,
      slot: moment(this.props.data.departDate).format('YYYY-MM-DD'),
      departTime: this.props.data.depart_time,
      arriveTime: this.props.data.arrive_stop.time,
      departAddress: this.props.data.depart_stop.stop.address,
      arriveAddress: this.props.data.arrive_stop.stop.address,
      departAddressDescription: this.props.data.depart_stop.stop.description,
      arriveAddressDescription: this.props.data.arrive_stop.stop.description,
      schedule_bus: this.props.data.departTrip.schedule_bus,
      bus_type: this.props.data.departTrip.bus_type.type,
      letter: this.props.data.departTrip.letter,
      remainingSeats: this.props.data.departTrip.remainingSeats
    };
    if(this.props.data.returnTrip) {
      body.returnStops = this.props.data.returnTrip.stops;
      body.departCityCode1 = this.getStopCode(this.props.data.returnDepartCity);
      body.returnDepartCity =  this.props.data.returnDepartCity;
      body.returnArriveCity =  this.props.data.returnArriveCity;
      body.scheduleId1 = this.props.data.returnTrip.scheduleId;
      body.depart_stop1 = this.props.data.depart_stop1.id;
      body.arrive_stop1 = this.props.data.arrive_stop1.id;
      body.slot1 = moment(this.props.data.returnDate).format('YYYY-MM-DD');
      body.departTime1 = this.props.data.depart_time1;
      body.arriveTime1 = this.props.data.arrive_stop1.time;
      body.departAddress1 = this.props.data.depart_stop1.stop.address;
      body.arriveAddress1 = this.props.data.arrive_stop1.stop.address;
      body.departAddressDescription1 = this.props.data.depart_stop1.stop.description;
      body.arriveAddressDescription1 = this.props.data.arrive_stop1.stop.description;
      body.schedule_bus1 = this.props.data.returnTrip.schedule_bus;
      body.bus_type1 = this.props.data.returnTrip.bus_type.type;
      body.letter1 = this.props.data.returnTrip.letter;
      body.remainingSeatsReturn = this.props.data.returnTrip.remainingSeats;
    }
    if(this.state.reservationFee) {
      body.reservationFee = this.state.reservationFee;
    }
    if(this.state.fuelSurcharge) {
      body.fuelSurcharge = this.state.fuelSurcharge;
    }
    if (this.state.useCoupon && this.state.coupon) {
      body.coupon = this.state.coupon;
      body.paymentMethod = 'Coupon';
    }
    if (this.state.useGift && this.state.giftCard) {
      body.giftCard = this.state.giftCard;
      body.paymentMethod = 'Gift Card';
    }
    if (this.state.useCredit && this.state.credit) {
      body.credit = this.state.credit;
      body.paymentMethod = 'Tripperbus Credit';
    }
    if (this.state.useVoucher && this.state.vouchersUsed) {
      body.voucher = this.state.vouchersUsed;
      body.voucherAmount = this.state.discount;
      body.paymentMethod = 'Tripperbus Points';
      // if(this.state.vouchersUsed > this.state.user.vouchers) {
      //   body.vouchersToAdd = this.state.availableVouchers - this.state.user.vouchers;
      //   body.voucherLegs = this.state.voucherLegs;
      // }
    }
    if (this.state.payment && this.state.card) {
      body.card = {
        cardNumber: this.state.card.number.split('-').join(''),
        expiryDate: '' + this.state.card.month + '' + this.state.card.year,
        cvv: this.state.card.cvv,
        zip: this.state.card.zip
      };
      body.payment = this.state.payment;
      body.paymentMethod = 'Card';
      body.cardPaymentBy = this.state.card.cardHolder;
    }
    if(this.props.data.reservationNumber) {
      body.reservationNumber = this.props.data.reservationNumber;
      body.oldSeats = parseInt(this.props.data.oldSeats);
    }
    
    if(this.props.data.reservationNumber) {
      body.reservationNumber = this.props.data.reservationNumber;
      body.oldSeats = parseInt(this.props.data.oldSeats);
      Order.reschedule(body)
      .then(result => {
        
        if(result.token) {
          setUser(result.token);
          eventTracker('purchase_ticket', 'click', 'Booking Summary Page');
          if(body.paymentMethod === 'Card'){
            purchaseEvent(this.createPurchaseEventDetails(body, result))
           }
        }
        let url = '/tickets-confirmed?seats='+this.props.data.seats + '&departCity=' + this.props.data.departCity + '&arriveCity=' + this.props.data.arriveCity + '&departTripCode=' + result.departTripCode + '&departDate=' + moment(this.props.data.departDate).format('MMMM D YYYY') + '&departTime=' + this.props.data.depart_time+'&departStop=' + this.props.data.depart_stop.stop.address+'&arriveStop=' + this.props.data.arrive_stop.stop.address + '&oldReservationNumber=' + this.props.data.reservationNumber + '&arriveTime=' + this.props.data.arrive_stop.time;
        this.setState({loading: false, departTripCode: result.departTripCode});
        if(result.returnTripCode) {
          url = url + '&returnDate=' + moment(this.props.data.returnDate).format('MMMM D YYYY') + '&returnTripCode=' + result.returnTripCode + '&returnDepartCity=' + this.props.data.returnDepartCity + '&returnArriveCity=' +  this.props.data.returnArriveCity  + '&returnTime=' + this.props.data.depart_time1+'&departStop1=' + this.props.data.depart_stop1.stop.address+'&arriveStop1=' + this.props.data.arrive_stop1.stop.address + '&oldReservationNumber=' + this.props.data.reservationNumber + '&returnArriveTime=' + this.props.data.arrive_stop1.time;
          this.setState({returnTripCode: result.returnTripCode});
        }
        browserHistory.push(url);
      })
      .catch(err => {
        let error = '';
        if(err && err.response && err.response.body && err.response.body.message) {
          error = err.response.body.message;
        }
        else {
          error = err.response.text || err.response.statusText;
        }
        showToast('error',  error);

        this.setState({loading: false, error: ""});
      });
    }
    else if (!this.state.user.id) {
      body.user = {
        email: this.state.user.email,
        phone: this.state.user.phone,
        password: this.state.user.password,
        firstName: this.state.firstName,
        lastName: this.state.lastName,
      };
      Order.registerAndCreateOrder(body)
      .then(result => {
        
        eventTracker('purchase_ticket', 'click', 'Booking Summary Page');

        if(body.paymentMethod === 'Card'){
          purchaseEvent(this.createPurchaseEventDetails(body, result))
        }
        let url = '/tickets-confirmed?seats='+this.props.data.seats + '&departCity=' + this.props.data.departCity + '&arriveCity=' + this.props.data.arriveCity + '&departTripCode=' + result.departTripCode + '&departDate=' + moment(this.props.data.departDate).format('MMMM D YYYY') + '&departTime=' + this.props.data.depart_time+'&departStop=' + this.props.data.depart_stop.stop.address+'&arriveStop=' + this.props.data.arrive_stop.stop.address + '&arriveTime=' + this.props.data.arrive_stop.time;
        this.setState({loading: false, departTripCode: result.departTripCode, confirmationCode: result.confirmationCode});
        if(result.returnTripCode) {
          url = url + '&returnDate=' + moment(this.props.data.returnDate).format('MMMM D YYYY') + '&returnTripCode=' + result.returnTripCode + '&returnDepartCity=' + this.props.data.returnDepartCity + '&returnArriveCity=' + this.props.data.returnArriveCity +'&returnTime=' + this.props.data.depart_time1+'&departStop1=' + this.props.data.depart_stop1.stop.address+'&arriveStop1=' + this.props.data.arrive_stop1.stop.address + '&returnArriveTime=' + this.props.data.arrive_stop1.time;
          this.setState({returnTripCode: result.returnTripCode});
        }
        if(!this.state.user.id && result.confirmationCode) {
          url = url + '&confirmationCode=' + result.confirmationCode;
        }
        browserHistory.push(url);
      })
      .catch(err => {
        
        if(err && typeof(err) == 'object' && JSON.stringify(err).indexOf('users_email_unique') > -1) {
          this.setState({loading: false, error: ''});
          showToast('error','Email already exists');

        }
        else {
          let error = '';
          if(err && err.response && err.response.body && err.response.body.message) {
            error = err.response.body.message;
          }
          else {
            error = err.response.text || err.response.statusText;
          }
          showToast('error',error);

          this.setState({loading: false, error: ""});
        }
      });
    }
    else if (this.state.user.id) {
      Order.createOrder(body)
      .then(result => {

        if(result.token) {
          setUser(result.token);
          const items = body.seats.map((seat, index)=>{
             return {
              item_id: result.returnTripCode ? `Seat${index}-${result.returnTripCode} `: `Seat${index}-${result.departTripCode}`,
              ...seat
             }
          })

          if(body.paymentMethod === 'Card'){
            purchaseEvent(this.createPurchaseEventDetails(body, result))
          }
        }
        let url = '/tickets-confirmed?seats='+this.props.data.seats + '&departCity=' + this.props.data.departCity + '&arriveCity=' + this.props.data.arriveCity + '&departTripCode=' + result.departTripCode + '&departDate=' + moment(this.props.data.departDate).format('MMMM D YYYY') + '&departTime=' + this.props.data.depart_time+'&arriveTime=' + this.props.data.arrive_stop.time+'&departStop=' + this.props.data.depart_stop.stop.address+'&arriveStop=' + this.props.data.arrive_stop.stop.address;
        this.setState({loading: false, departTripCode: result.departTripCode});
        if(result.returnTripCode) {
          url = url + '&returnDate=' + moment(this.props.data.returnDate).format('MMMM D YYYY') + '&returnTripCode=' + result.returnTripCode  + '&returnDepartCity=' + this.props.data.returnDepartCity + '&returnArriveCity=' + this.props.data.returnArriveCity  + '&returnTime=' + this.props.data.depart_time1+ '&returnArriveTime=' + this.props.data.arrive_stop1.time +'&departStop1=' + this.props.data.depart_stop1.stop.address+'&arriveStop1=' + this.props.data.arrive_stop1.stop.address;
          this.setState({returnTripCode: result.returnTripCode});
        }
        browserHistory.push(url);
      })
      .catch(err => {
        let error = '';
        if(err && err.response && err.response.body && err.response.body.message) {
          error = err.response.body.message;
        }
        else {
          error = (err.response && (err.response.text || err.response.statusText)) || 'Error';
        }
        showToast('error',error);

        this.setState({loading: false, error: ""});
      });
    }
  }

  handleCardChange(e) {
    this.setState({['card_'+e.target.name+'_error']: ''});
    let card = this.state.card;
    card[e.target.name] = e.target.value;
    this.setState({ card: card, submitted: false });
    if(e.target.name === 'number' && e.target.value && e.target.value.length === 19) {
      window.$('select[name=month]').focus();
    }
  }

  handleCardBlur(e) {
    if(e.target.required && !e.target.value) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'cvv' && (/\d{3}/.test(e.target.value) === false && /\d{4}/.test(e.target.value) === false)) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'zip' && /\d{5}([\-]\d{4})?/.test(e.target.value) === false) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'cardHolder' && /^([A-Za-z\s.]{3,})$/.test(e.target.value) === false) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(e.target.name === 'number' && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{4}/.test(e.target.value) === false && /\d{4}[\s.-]\d{4}[\s.-]\d{4}[\s.-]\d{3}/.test(e.target.value) === false) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['card_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else {
      let error = {};
      error['card_'+e.target.name+'_error'] = '';
      this.setState(error);
    }
  }

  handleUserInput(e) {
    this.setState({['user_'+e.target.name+'_error']: '', 'auth_userName_error': '', 'auth_secret_error': ''});
    let user = this.state.user;
    user[e.target.name] = e.target.value;
    this.setState({ user: user, submitted: false });
  }

  handleInput(e) {
    this.setState({[e.target.name+'_error']: ''});
    
    this.setState({ [e.target.name]: e.target.value, submitted: false });
  }

  handleUserBlur(e) {
    this.setState({['user_'+e.target.name+'_error']: ''});
    if(e.target.required && !e.target.value) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(e.target.name === 'email' && e.target.value && this.emailRegex.test(e.target.value) === false) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    // else if(e.target.name === 'confirmEmail' && this.state.user.email && (this.state.user.email.toLowerCase() !== e.target.value.toLowerCase())) {
    //   let error = {};
    //   error['user_'+e.target.name+'_error'] = 'Email and confirm email should be same';
    //   this.setState(error);
    // }
    // else if(e.target.name === 'email' && this.state.user.confirmEmail && (e.target.value.toLowerCase() !== this.state.user.confirmEmail.toLowerCase())) {
    //   let error = {};
    //   error['user_confirmEmail_error'] = 'Email and confirm email should be same';
    //   this.setState(error);
    // }
    else if(this.state.createAccount && e.target.name === 'confirmPassword' && this.state.user.password && (this.state.user.password !== e.target.value)) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Password and confirm password should be same';
      this.setState(error);
    }
    else if(this.state.createAccount && e.target.name === 'password' && this.state.user.confirmPassword && (e.target.value !== this.state.user.confirmPassword)) {
      let error = {};
      error['user_confirmPassword_error'] = 'Password and confirm password should be same';
      this.setState(error);
    }
    else if(e.target.name === 'phone' && e.target.value && /\(\d{3}\)[\s.-]\d{3}[\s.-]\d{4}/.test(e.target.value) === false) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error['user_'+e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
  }

  handleBlur(e) {
    this.setState({[e.target.name+'_error']: ''});
    if(e.target.required && !e.target.value) {
      let error = {};
      error[e.target.name+'_error'] = 'Required';
      this.setState(error);
    }
    else if(this.refs[e.target.name].validity && !this.refs[e.target.name].validity.valid) {
      let error = {};
      error[e.target.name+'_error'] = 'Invalid';
      this.setState(error);
    }
  }

  getDiscountName() {
    if(this.state.vouchersDiscount) {
      return 'Points';
    }
    if(this.state.coupon) {
      return 'Coupon';
    }
    if(this.state.credit) {
      return 'Credit';
    }
    if(this.state.giftCard) {
      return 'Gift';
    }
  }

  logout(){
    Auth.logout();
  }

  applyPoints() {
    if(this.state.vouchersDiscount) {
      this.setState({vouchersDiscount: 0, vouchersUsed: 0, credit: 0, useVoucher: false}, () => {
        this.calculatePayment();
      });
      return;
    }
    let payment = this.state.payment + this.state.discount;
    this.setState({vouchersDiscount: 0, vouchersUsed: 0, credit: 0, payment: payment});
    this.setState({ useCoupon: false, useCredit: false, useGift: false, useVoucher: true, coupon: null });
    let vouchers = this.state.availableVouchers;
    let vouchersUsed = 0;
    let discount = 0;
    
    for(let i = 0; i < this.props.data.seats; i++) {
      if (vouchers >= this.state.vouchersForFreeTicketDepart && discount < (payment - this.state.reservationFee + this.state.discount)) {
        if (this.props.data.busType === 'Elite' && this.props.data.oldSeats && this.props.data.points && i < (this.props.data.oldSeats)) {
          vouchersUsed = vouchersUsed + 12;
          vouchers = vouchers - 12;
        }
        else {
          vouchersUsed = vouchersUsed + this.state.vouchersForFreeTicketDepart;
          vouchers = vouchers - this.state.vouchersForFreeTicketDepart;
        }
        discount = discount + this.props.data.departTrip.price;
      }
    }
    if (this.props.data.returnDate && this.props.data.returnTrip) {
      for(let j = 0; j < this.props.data.seats; j++) {
        if (vouchers >= this.state.vouchersForFreeTicketReturn && discount < (payment - this.state.reservationFee + this.state.discount)) {
          vouchersUsed = vouchersUsed + this.state.vouchersForFreeTicketReturn;
          discount = discount + this.props.data.returnTrip.price;
          vouchers = vouchers - this.state.vouchersForFreeTicketReturn;
        }
      }
    }
    this.setState({vouchersDiscount: discount, vouchersUsed: vouchersUsed}, () => {
      this.calculatePayment();
    });
  }

  applyCredit() {
    if(this.state.credit) {
      this.setState({vouchersDiscount: 0, vouchersUsed: 0, credit: 0, useCredit: false}, () => {
        this.calculatePayment();
      });
      return;
    }
    let payment = this.state.payment + this.state.discount;
    this.setState({vouchersDiscount: 0, vouchersUsed: 0, credit: 0, payment: payment});
    this.setState({ useCoupon: false, useCredit: true, useGift: false, useVoucher: false, coupon: null });
    if(this.state.availableCredit >= (payment + this.state.discount)) {
      this.setState({credit: payment}, () => {
        this.calculatePayment();
      });
    }
    else {
      this.setState({credit: this.state.availableCredit}, () => {
        this.calculatePayment();
      });
    }
  }

  onlyPointsAllowed() {
    if (this.props.data.reservationNumber && this.props.data.points) {
      if (!this.props.data.returnTrip && this.props.data.oldPrice >= this.props.data.departTrip.price) {
        if (parseInt(this.props.data.oldSeats) >= parseInt(this.props.data.seats)) {
          return true;
        }
      }
    }
    return false;
  }

  pointsAllowed() {
    if (this.props.data.reservationNumber) {
      if (this.props.data.returnTrip) {
        return false;
      }
      if (this.props.data.amount) {
        return false;
      }
      if (this.props.data.oldPrice < this.props.data.departTrip.price && this.props.data.busType === this.props.data.departTrip.bus_type.type) {
        return false;
      }
      if (parseInt(this.props.data.oldSeats) < parseInt(this.props.data.seats)) {
        return false;
      }
    }
    return true;
  }

  calculatePaymentWithoutDiscount(){
    let total = 0;
    let seats = this.props.data.seats;

    if (this.props.data.departTrip) {
      total += this.props.data.departTrip.price * seats;
    }
    if (this.props.data.returnTrip) {
      total += this.props.data.returnTrip.price * seats;
    }
    total = total + this.state.reservationFee + this.state.fuelSurcharge;

    return total;
  }


  // returns the type of discoun being applied
  //if the discount type is coupon code then it will only be displayed if the coupon amount is less than total amount before any deductions
  //if the coupon amount is more than the total amount before any deduction, display nothing
  // showDiscountSection(){
  //   const discountName = this.getDiscountName()
  //   const discountAmount = this.state.discount;
  //   let  currentTotal  = 0;

  //   currentTotal = this.props.data.departTrip.price * this.props.data.seats;
  //   if(this.props.data.returnTrip){
  //     currentTotal += this.props.data.returnTrip.price * this.props.data.seats;
  //   }

  //   if(discountName === 'Coupon' && this.state.coupon.amount > this.calculatePaymentWithoutDiscount()){
  //     return null;
  //   }

  //   if(discountName === 'Credit'){
  //     if(this.props.data.amount > currentTotal){
  //       return(
  //       <tr>
  //         <td className="credit-success">{'Credited Back'}:</td>
  //         <td className="bold credit-success">{'+'}{numeral(this.props.data.amount - discountAmount).format('$0,0.00')}</td>
  //       </tr>
  //     )
  //     }else{
  //       return(
  //         <tr>
  //           <td className="discount">{'Credit Used'}:</td>
  //           <td className="bold discount">{numeral( discountAmount- this.props.data.amount).format('$0,0.00')}</td>
  //         </tr>
  //       )
  //     }
  //   }

  //   return (
  //     <tr>
  //       <td className="discount">{discountName}:</td>
  //       <td className="bold discount">{numeral(discountAmount).format('$0,0.00')}</td>
  //     </tr>
  //   )
  // }
  showDiscountSection(){
    const discountName = this.getDiscountName()
    const discountAmount = this.state.discount;
    let  currentTotal  = 0;

    currentTotal = this.props.data.departTrip.price * this.props.data.seats;
    if(this.props.data.returnTrip){
      currentTotal += this.props.data.returnTrip.price * this.props.data.seats;
    }

    if(discountName === 'Coupon' && this.state.coupon.amount > this.calculatePaymentWithoutDiscount()){
      return  <div className='grid price-summary'>
      <div className="discount">{discountName}:</div>
      <div  className='only-desktop'></div>
      <div className="discount">{`${'-' + numeral(this.calculatePaymentWithoutDiscount()).format('$0,0.00')}`}</div>
    </div>
    }

    if(discountName === 'Credit'){
      if(this.props.data.amount > currentTotal){
        return(
        <div className='grid price-summary'>
          <div className="credit-success ">{'Returned Credit'}:</div>
          <div  className='only-desktop'></div>
          <div className="credit-success">{'+'}{numeral(this.props.data.amount - discountAmount).format('$0,0.00')}</div>
        </div>
      )
      }else {
        if(discountAmount- this.props.data.amount <=0 ) return null;
        return(
          <div className='grid price-summary'>
            <div className="discount">{'Credit Used'}:</div>
            <div  className='only-desktop'></div>
            <div className="discount">{'-' }{numeral( discountAmount- this.props.data.amount).format('$0,0.00')}</div>
          </div>
        )
      }
    }

    return (
      <div className='grid final-summary'>
        <div className="discount">{discountName}:</div>
        <div className="bold discount">{`-` + numeral(discountAmount).format('$0,0.00')}</div>
      </div>
    )
  }

  getSubtotal() {
    
    let currentTotal = this.props.data.departTrip.price * this.props.data.seats;
    if(this.props.data.returnTrip){
      currentTotal += this.props.data.returnTrip.price * this.props.data.seats;
    }

    currentTotal += this.state.reservationFee + this.state.fuelSurcharge;

    return currentTotal;
  }


  scrollTop() {
    let el = document.querySelector('.content-body');
    if(el.classList.contains('scroll-top')) {
      el.classList.remove('scroll-top');
      document.body.style.overflowY = 'auto';
      return;
    }
    el.classList.add('scroll-top');
    document.body.style.overflow = 'hidden';
  }

  getNumberOfSeats() {
    if(this.props.data.seats == 1) {
      return '1 seat';
    } else{
      return this.props.data.seats + ' seats';
    }
  }

  render() {
    return (
      <div className={'container' + (this.props.data.reservationNumber ? ' res-container' : '')}>
        <div className="confirmTrip">
          {/* <div className="content-header step-a">
            <div className="heading">Confirm your <span className="bold">trip details</span></div>
            <button type="button" onClick={this.props.editTrip.bind(this)} className="btn btn-blue edit-trip-btn">EDIT TRIP</button>
          </div> */}

            <div className="row clearfix">
              <div className="col-md-8">
              <div className="tripDetails">
                <Itinerary 
                    data = {
                     { 
                      seats: this.props.data.seats,
                      isRoundTripSelected: this.props.data.isRoundTripSelected,
                      departDate: this.props.data.departDate,
                      departCity: this.props.data.departCity,
                      arriveCity: this.props.data.arriveCity,
                      depart_stop: this.props.data.depart_stop,
                      arrive_stop: this.props.data.arrive_stop,
                      returnDate:  this.props.data.returnDate,
                      returnDepartCity: this.props.data.returnDepartCity,
                      returnArriveCity: this.props.data.returnArriveCity,
                      depart_stop1: this.props.data.depart_stop1,
                      arrive_stop1: this.props.data.arrive_stop1,

                     }
                    }

                    editTrip = {this.props.editTrip}

                  
                  />
              {/* <p className="seatBook"><img src={chairIcon} width="15" alt="" /> Seats: <span className="green-text">{this.props.data.seats}</span></p> */}
              {/* <div className="form-group clearfix tripper-card">
                    <div className="col-sm-6 departingTrip">
                      <p className="tripType">Departing Trip:</p>
                      <p className="tripAddress">{this.props.getStopLabelWithZip(this.props.data.departCity)} to {this.props.getStopLabelWithZip(this.props.data.arriveCity)}</p>
                      <p>{moment(this.props.data.departDate).format('MMMM D YYYY')} at <span className="bold">{moment(this.props.data.depart_stop.time, 'HH:mm:ss').format('hh:mm a')}</span></p>
                      <div className="pickUp">
                        <p>Pick up:</p>
                        <p className="green-text"><Link to="/locations" target="_blank" externalLink={true}>{this.props.data.depart_stop.stop.address}</Link></p>
                        {this.props.data.depart_stop.stop.city === "washington" && <p className="description">Dupont Circle</p>}
                        <p className="description">{this.props.data.depart_stop.stop.description}</p>
                      </div>
                      <div className="dropOff">
                        <p>Drop off:</p>
                        <p className="green-text"><Link to="/locations" target="_blank" externalLink={true}>{this.props.data.arrive_stop.stop.address}</Link></p>
                        {this.props.data.arrive_stop.stop.city === "washington" && <p className="description">Dupont Circle</p>}
                        <p className="description">{this.props.data.arrive_stop.stop.description}</p>
                      </div>
                    </div>
                    {this.props.data.returnTrip && <div className="col-sm-6 returningTrip">
                      <p className="tripType">Returning Trip: </p>
                      <p className="tripAddress">{this.props.getStopLabelWithZip(this.props.data.returnDepartCity)} to {this.props.getStopLabelWithZip(this.props.data.returnArriveCity)}</p>
                      <p>{moment(this.props.data.returnDate).format('MMMM D YYYY')} at <span className="bold">{moment(this.props.data.depart_stop1.time, 'HH:mm:ss').format('hh:mm a')}</span></p>
                      <div className="pickUp">
                        <p>Pick up:</p>
                        <p className="blue-text"><Link to="/locations" target="_blank" externalLink={true}>{this.props.data.depart_stop1.stop.address}</Link></p>
                        {this.props.data.depart_stop1.stop.city === "washington" && <p className="description">Dupont Circle</p>}        
                        <p className="description">{this.props.data.depart_stop1.stop.description}</p>
                      </div>
                      <div className="dropOff">
                        <p>Drop off:</p>
                        <p className="blue-text"><Link to="/locations" target="_blank" externalLink={true}>{this.props.data.arrive_stop1.stop.address}</Link></p>
                        {this.props.data.arrive_stop1.stop.city === "washington" && <p className="description">Dupont Circle</p>}
                        <p className="description">{this.props.data.arrive_stop1.stop.description}</p>
                      </div>
                    </div>}
                  </div> */}
                </div>

               

                {/* {(!this.state.user.id && this.props.data.reservationNumber && this.props.data.amount) ? <div className="customerInformation already-in pos-rel">
                  <div className="row clearfix signed-in step-b">
                    <div className="col-sm-8">
                      <h3>{this.state.availableCredit - this.state.credit} <span>Credits</span></h3>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-blue" disabled={!this.state.availableCredit || this.onlyPointsAllowed()} onClick={this.applyCredit.bind(this)}>{!this.state.credit ? 'APPLY CREDIT' : 'REMOVE CREDIT'}</button>
                    </div>
                  </div>
                </div> : ''} */}

                {/* {(!this.state.user.id && this.props.data.reservationNumber && this.props.data.points) ? <div className="customerInformation already-in pos-rel">
                  <div className="row clearfix signed-in step-b">
                    <div className="col-sm-8">
                      <h3>{this.state.availableCredit} <span>Credits</span></h3>
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-blue" disabled={!this.state.availableCredit || this.onlyPointsAllowed()} onClick={this.applyCredit.bind(this)}>{!this.state.credit ? 'APPLY CREDIT' : 'REMOVE CREDIT'}</button>
                    </div>
                    <div className="col-sm-8">
                      <h3>{this.state.availableVouchers - this.state.vouchersUsed} <span>Points</span></h3>
                      {this.state.availableVouchers < 6 ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 6 points qualifies you to a free <strong>STANDARD</strong> bus ticket.</div> : ''}
                      {this.state.availableVouchers > 6 && this.checkEliteOnly() ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 12 points qualifies you to a free ELITE bus ticket.</div> : ''}
                      {this.state.availableVouchers > 6 && !this.checkEliteOnly() ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 6 points qualifies you to a free standard bus ticket.</div> : ''}
                    </div>
                    <div className="col-sm-4">
                      <button className="btn btn-blue" disabled={!this.state.availableVouchers || (this.state.availableVouchers < this.state.vouchersForFreeTicketDepart) || this.onlyPointsAllowed() || !this.pointsAllowed()} onClick={this.applyPoints.bind(this)}>{!this.state.vouchersDiscount ? 'APPLY POINTS' : 'REMOVE POINTS'}</button>
                    </div>
                  </div>
                </div> : ''} */}


                {!this.state.continueAsGuest && !this.state.isLoggedIn &&  <Login onContinueAsGuest={()=>{this.setState({continueAsGuest: true, user: this.state.guestUser || this.state.user })}}>
                {!this.state.isLoggedIn && <div className="customerInformation">
                  <div className="lSecHeader step-b">
                    <h4>Log Into your Account</h4>
                  </div>
                  <div className="row clearfix">
                    <div className="col-sm-12 form-group">
                      <label className="formLable">E-MAIL</label>
                      <input required type="email" ref="userName" name="userName" value={this.state.auth.userName} onBlur={this.handleLoginBlur.bind(this)} onChange={this.handleLoginInput.bind(this)} className="form-control username"/>
                      <div className="error">{this.state.auth_userName_error}</div>
                    </div>
                    <div className="col-sm-12 form-group">
                      <label className="formLable">PASSWORD</label>
                      <input required  ref="secret" type="password" name="secret" value={this.state.auth.secret} onBlur={this.handleLoginBlur.bind(this)} onChange={this.handleLoginInput.bind(this)} className="form-control password"/>
                      <div className="error">{this.state.auth_secret_error}</div>
                    </div>
                  </div>
                  <div className="row form-group clearfix">
                  <div className="col-sm-6">
                    <label className='rememberme'>
                      <input type="checkbox"/> Remember Me
                    </label>
                    </div>
                    <div className="col-sm-6 text-end">
                      <a className='resetPassword' onClick={()=>this.showForgotPasswordModal()}>Forgot Password?</a>
                    </div>
                  </div>

                  <div className='row'>
                    <div className="col-sm-12">
                      <button type="button" disabled={this.state.loading} onClick={this.login.bind(this)} className="btn btn-blue btn-block">Login</button>
                    </div>
                  </div>

                  {this.state.loginError && <div className="error">{this.state.loginError}</div>}

                  <div className='row register-link'>
                    <div className="col-sm-12">
                     {"Not a user yet ? "}<a className='resetPassword' href={siteurl + '/registration'} target="_blank">Register Now</a>
                    </div>
                  </div>

                </div>}
                </Login>}
                <Modal>
                      <ResetPassword/>
                    </Modal>
                
                {!this.state.user.id  && this.state.continueAsGuest && <div className="registerForm tripper-card">
                <div className="lSecHeader two-col">
                    <span className="bold">Customer Info</span>
                    <span onClick={()=>this.setState({currentStep: 1})} className='edit'>{this.state.currentStep !== 1 ? 'Edit' : ''}</span>
                  </div>
                  {this.state.currentStep == 1 ? <div className='customer-grid'>
                    <div className="row clearfix">
                      <div className="col-sm-6 form-group">
                        <label className="formLable">EMAIL</label>
                        <input ref="email" required name="email" onBlur={this.handleUserBlur.bind(this)} onChange={this.handleUserInput.bind(this)} value={this.state.user.email} className={'form-control' + (this.state.user_email_error ? ' error' : '')} placeholder="Email" />
                        <div className="error">{this.state.user_email_error}</div>
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="formLable">Mobile Number</label>
                            <div className="form-group">
                              <InputMask mask="(999) 999-9999" ref="phone" onBlur={this.handleUserBlur.bind(this)} onChange={this.handleUserInput.bind(this)} value={this.state.user.phone} name="phone" required  className={'form-control' + (this.state.user_phone_error ? ' error' : '')} placeholder="" />
                            </div>
                            <div className="error">{this.state.user_phone_error}</div>
                          </div>
                    </div>
                    {this.state.createAccount && <div className="row clearfix">
                      <div className="col-sm-6 form-group">
                        <label className="formLable">PASSWORD</label>
                        <input ref="password" required={this.state.createAccount} name="password" onBlur={this.handleUserBlur.bind(this)} onChange={this.handleUserInput.bind(this)} value={this.state.user.password} type="password" className={'form-control' + (this.state.user_password_error ? ' error' : '')} placeholder="Password" />
                        <div className="error">{this.state.user_password_error}</div>
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="formLable">CONFIRM PASSWORD</label>
                        <input ref="confirmPassword" required={this.state.createAccount} name="confirmPassword" onBlur={this.handleUserBlur.bind(this)} onChange={this.handleUserInput.bind(this)} value={this.state.user.confirmPassword} type="password" className={'form-control' + (this.state.user_confirmPassword_error ? ' error' : '')} placeholder="Confirm Password" />
                        <div className="error">{this.state.user_confirmPassword_error}</div>
                      </div>
                    </div>}
                    <Checkbox onChange={(value)=>{
                    this.setState({isMarketingChecked: value});
                    }} checked={this.state.isMarketingChecked} label="I agree to receive text messages from Tripper Bus with updates regarding my reservation" />
                    <div className="form-group clearfix">
                      <label className="createAccount checkbox"><input type="checkbox" value={this.state.createAccount} checked={this.state.createAccount} onChange={this.handleCreateAccount.bind(this)} /> <span className="checkmark"></span> <span className="m-28 bold">Create my account (buy six tickets and get one free!)</span> </label>
                    </div>
                    {/* <div className="row form-group clearfix">
                      <div className="col-sm-8">
                        <div className="row">
                          <div className="col-3 col-sm-3 col-md-2">
                            <img src={smsIcon} alt="" />
                          </div>
  
                        </div>
                      </div>
                    </div> */}
                    <div className='end'>
                      <button className='tripper-button' disabled={this.checkIsCustomerInfoDisabled()} onClick={()=>this.setState({currentStep: 2})}>Next: Primary Passenger</button>
                    </div>
                  </div>
                  
                  :

                  <div>
                    <div className="row clearfix">
                      <div className="col-sm-6 form-group">
                        <p>{this.state.user.email}</p>
                        <p>{this.state.user.phone}</p>
                        <p>{"".padStart(this.state.user.password.length,"*")}</p>
                      </div>
                      </div>
                  </div>
                  
                }
                </div>}

                {this.state.user.id && !this.props.data.reservationNumber && !UserStore.getUser().phone && <div className="registerForm">
                  <div className="row form-group clearfix">
                    <div className="col-sm-8">
                      <div className="row">
                        <div className="col-3 col-sm-3 col-md-2">
                          <img src={smsIcon} alt="sms icon" />
                        </div>
                        <div className="col-9 col-sm-9 col-md-10 stayUpdate">
                          <h4>Stay Updated on your trip</h4>
                          <p>Stay updated via text on any delays and updates</p>
                          <div className="form-group">
                            <InputMask mask="(999) 999-9999" ref="phone" onBlur={this.handleUserBlur.bind(this)} onChange={this.handleUserInput.bind(this)} value={this.state.user.phone} name="phone" required  className={'form-control' + (this.state.user_phone_error ? ' error' : '')} placeholder="" />
                          </div>
                          <label className="formLable">Mobile Number</label>
                          <div className="error">{this.state.user_phone_error}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>}

                <div className="registerForm tripper-card">
                  <div className="lSecHeader two-col">
                    <span className="bold">Primary Passenger</span>
                    <span onClick={()=>this.setState({currentStep: 2})} className='edit'>{this.state.currentStep !== 2 && this.state.firstName && this.state.lastName ? 'Edit' : ''}</span>
                  </div>
                  {
                     <div className='customer-grid'>
                      {
                          this.state.firstName && this.state.lastName && this.state.currentStep !== 2 ? 
                          
                          <div>
                            {this.state.firstName + ' ' + this.state.lastName}
                          </div>
                          :
                        this.state.currentStep == 2  && <div className="row clearfix">
                          <div className="col-sm-6 form-group">
                            <label className="formLable">FIRST NAME</label>
                            <input ref="firstName" required name="firstName" onBlur={this.handleBlur.bind(this)} onChange={this.handleInput.bind(this)} value={this.state.firstName} type="text" className={'form-control' + (this.state.firstName_error ? ' error' : '')} placeholder="First Name" />
                            <div className="error">{this.state.firstName_error}</div>
                          </div>
                          <div className="col-sm-6 form-group">
                            <label className="formLable">LAST NAME</label>
                            <input ref="lastName" required name="lastName" onBlur={this.handleBlur.bind(this)} onChange={this.handleInput.bind(this)} value={this.state.lastName} type="text"  className={'form-control' + (this.state.lastName_error ? ' error' : '')} placeholder="Last Name" />
                            <div className="error">{this.state.lastName_error}</div>
                          </div>
                        </div>
                      }
                    {this.state.currentStep == 2  && <button className='tripper-button end' disabled={!this.state.firstName || !this.state.lastName} onClick={()=>{this.setState({currentStep: 3})}}>Next: Review & Pay</button>}
                  </div>
                  }
              
                </div>

                <div className="customerInformation tripper-card review">
                <div className="lSecHeader step-c">
                    <span>Review & Pay</span>
                  </div>
                  {((this.state.user.id || this.state.continueAsGuest) && this.state.currentStep == 3 )&& <div>


                    <div className="content-body">
            <div className="row clearfix only-mobile">
            <div>
                  <div className="order-summary only-mobile">
                        <div className="head-title">
                          <span>Review</span>
                        </div>

                        <div className='grid tripsum'>
                        <div className="depart-trip-time-data">
                              <div>{this.props.data.departDate.format('MMM DD')}</div>
                              <div className='city'>{this.props.data.departCity}</div>
                              <img src={routeIcon} width="11px" height="10px" alt='route icon' />
                              <div className='city'>{this.props.data.arriveCity}</div>
                            </div>
                            <div className="depart-trip-time">
                              <div>{this.props.data.departDate.format('dddd')}</div>
                               <div>{moment(this.props.data.depart_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                               <div></div>
                               <div>{moment(this.props.data.arrive_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                            {/* <div><span>Approximate duration: 4hs (subject to traffic, weather and stop locations)</span></div> */}
                        </div>


                        <div className='grid price-summary border-bottom'>
                        {/* <span><img src={personIcon} height="24px" width="24px"/></span> */}
                          <div>{this.getNumberOfSeats()} {this.props.data.departTrip.bus_type.type === 'Elite' &&  <img src={eliteIcon} height="20px" alt="elite bus icon"/>}</div>
                          <div>{numeral(this.props.data.departTrip.price*this.props.data.seats).format('$0,0.00')}</div>
                        </div>


                        {this.props.data.returnTrip ?
                        <div>

                        <div className='grid tripsum'>
                            <div className="depart-trip-time-data">
                            <div>{this.props.data.returnDate.format('MMM DD')}</div>
                              <div className='city'>{this.props.data.returnDepartCity}</div>
                              <img src={routeIcon} width="11px" height="10px" alt='route icon' />
                              <div className='city'>{this.props.data.returnArriveCity}</div>
                            </div>
                            <div className="depart-trip-time">
                            <div>{this.props.data.returnDate.format('dddd')}</div>
                               <div>{moment(this.props.data.depart_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                               <div></div>
                               <div>{moment(this.props.data.arrive_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                        </div>


                      <div className='grid price-summary border-bottom'>
                        {/* <span><img src={personIcon} height="24px" width="24px"/></span> */}
                          <div>{this.getNumberOfSeats()}{this.props.data.returnTrip.bus_type.type === 'Elite' && <img src={eliteIcon} height="20px" alt="elite bus icon"/>}</div>
                          <div>{numeral(this.props.data.returnTrip.price*this.props.data.seats).format('$0,0.00')}</div>
                        </div>
                        </div>: null
                      }


                        <div className='grid price-summary'>
                        {/* <span><img src={reservationFeeIcon} height="24px" width="24px"/></span> */}
                          <div>Reservation Fee</div>
                          <div>{this.state.reservationFee && numeral(this.state.reservationFee).format('$0,0.00')}</div>
                        </div>
                        <div className='grid price-summary'>
                        {/* <span><img src={fuelFeeIcon} height="24px" width="24px"/></span> */}
                          <div>Fuel Surcharge</div>
                          <div>{this.state.fuelSurcharge && numeral(this.state.fuelSurcharge).format('$0,0.00')}</div>
                        </div>


                        <div className='grid price-summary border-bottom'>
                        {/* <span><img src={fuelFeeIcon} height="24px" width="24px"/></span> */}
                          <div>Subtotal</div>
                          <div>{numeral(this.getSubtotal()).format('$0,0.00')}</div>
                        </div>

                        
                        {this.props.isEditing && this.state.discount != 0 &&
                          <div className='grid price-summary'>
                            <div className='discount'>Original Ticket:</div>
                            <div className='discount'>{'- '}${this.props.data.amount}</div>
                          </div>
                        }

                            {this.state.discount != 0 && this.showDiscountSection()}
                        {/* <div className='mobile-divider'></div> */}
                        <div className='grid final-summary'>
                          <div>
                            Total
                          </div>
                          <div>
                              ${numeral(this.state.payment).format('0,0.00')}
                          </div>
                        </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="grid head-title only-mobile">
                          <span>Payment</span>
                        </div>
                  <div>
                    <p>Special Offers</p>
                  </div>
                  <div className="form-group clearfix">
                    <label className="radio"><input type="radio" name="r2" disabled={this.props.data.reservationNumber} value={this.state.useGift} checked={this.state.useGift} onChange={this.handleDiscounts.bind(this)}
                     name="useGift" />
                     <span className="checkmark"></span>
                      Gift Card
                    </label>
                    {/* <label className="radio"><input type="radio" name="r2" disabled={!this.state.user.id || !this.state.availableCredit || this.onlyPointsAllowed()} value={this.state.useCredit} checked={this.state.useCredit} onChange={this.handleDiscounts.bind(this)}
                     name="useCredit" />
                     <span className="checkmark"></span>
                      Tripperbus Credit
                    </label> */}
                    {/* <label className="radio"><input type="radio" name="r2" disabled={!this.state.user.id || (this.state.availableVouchers < this.state.vouchersForFreeTicketDepart) || !this.pointsAllowed()} value={this.state.useVoucher} checked={this.state.useVoucher} onChange={this.handleDiscounts.bind(this)}
                     name="useVoucher" />
                     <span className="checkmark"></span>
                      Vouchers
                    </label> */}
                    <label className="radio"><input type="radio" name="r2" value={this.state.useCoupon} checked={this.state.useCoupon} onChange={this.handleDiscounts.bind(this)}
                     name="useCoupon" />
                     <span className="checkmark"></span>
                      Promo Code
                    </label>
                    {this.state.isPromoCodeAllowed !== true && (
                      <div className="error">
                        Call office <a className='coupon' href="tel:7188349214">(718) 834-9214</a> to apply promo code if applicable
                      </div>
                    )}

                  </div>


                  {this.state.useGift && <div className="form-group clearfix">
                    <label className="formLable">CARD#</label>
                    <div className="input-group">
                      <input name="giftCardCode" value={this.state.giftCardCode} onChange={this.handleChange.bind(this)} type="text" className="form-control" placeholder="Card#" />
                      <button type="button" onClick={this.verifyGiftCard.bind(this)} disabled={this.state.loading || !this.state.giftCardCode} className="btn tripper-button">APPLY</button>
                    </div>
                    {this.state.giftError && <div className="error">{this.state.giftError}</div>}
                  </div>}
                  {this.state.useCoupon && <div className="form-group clearfix">
                    <label className="formLable">CODE#</label>
                    <div className="input-group">
                      <input name="couponCode" value={this.state.couponCode} onChange={this.handleChange.bind(this)} type="text" className="form-control" disabled={this.props.data.reservationNumber} placeholder="Enter Promo Code" />
                      <button type="button" onClick={this.verifyCoupon.bind(this)} disabled={this.state.loading || !this.state.couponCode || this.props.data.reservationNumber} className="btn tripper-button">APPLY</button>
                    </div>
                    {this.state.couponError && <div className="error">{this.state.couponError}</div>}
                    {/* {this.state.coupon && <div className="success">Promo worth {this.state.coupon.type === '$' ? '$' : ''}{this.state.coupon.amount}{this.state.coupon.type === '%' ? '%' : ''} successfully applied.</div>} */}
                    {this.state.coupon && <div className="success">Promo code successfully applied.</div>}
                  </div>}

                   {/* {this.state.user.id && <div className="customerInformation already-in tripper-card"> */}
                   {(this.state.user.id || this.props.data.reservationNumber) && <div>
                  {/* <div className="lSecHeader step-b">
                    <h4>Customer Information</h4>
                    <p>Reserving online with Tripper Bus Service is extremely safe. All information is encrypted.</p>
                  </div> */}
                  {/* <div className="row clearfix signed-in">
                    <div className="col-sm-8">
                      Signed in as <span className="email">{this.state.user.email}</span>
                    </div>
                    <div className="col-sm-4">
                      <div className="not-u">Not you? <span className="sign-out" onClick={this.logout.bind(this)}>Sign out</span></div>
                    </div>
                  </div> */}
                  <div className="clearfix signed-in sign-border special-offer">
                    <div className="">
                      <p>{this.state.availableVouchers - this.state.vouchersUsed} <span>Points</span></p>
                      {/* {this.state.availableVouchers < 6 ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 6 points qualifies you to a free <strong>STANDARD</strong> bus ticket.</div> : ''}
                      {this.state.availableVouchers > 6 && this.checkEliteOnly() ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 12 points qualifies you to a free ELITE bus ticket.</div> : ''}
                      {this.state.availableVouchers > 6 && !this.checkEliteOnly() ? <div className="voucher-have">You have {this.state.availableVouchers} points in your account. Every 6 points qualifies you to a free standard bus ticket.</div> : ''} */}
                    </div>
                    <div className="">
                      <span className="" disabled={!this.state.availableVouchers || (this.state.availableVouchers < this.state.vouchersForFreeTicketDepart) || this.onlyPointsAllowed() || !this.pointsAllowed()} onClick={this.applyPoints.bind(this)}>{!this.state.vouchersDiscount ? 'APPLY POINTS' : 'REMOVE POINTS'}</span>
                    </div>
                  </div>
                  <div className="clearfix signed-in special-offer">
                    <div className="">
                      <p>{this.state.availableCredit - this.state.credit} <span>Credits</span></p>
                    </div>
                    <div className="">
                      <span className="" disabled={!this.state.availableCredit || this.onlyPointsAllowed()} onClick={this.applyCredit.bind(this)}>{!this.state.credit ? 'APPLY CREDIT' : 'REMOVE CREDIT'}</span>
                    </div>
                  </div>
                </div>}

                      <hr/>

                  {this.state.payment > 0 && <div>
                    <div className="form-group clearfix">
                      <label className="radio"><input type="radio" name="r1" onChange={this.changePaymentMethod.bind(this, 'cc')} checked={this.state.paymentMethod === 'cc'} />
                      <span className="checkmark"></span>
                        Credit Card
                      </label>
                      {/*<label className="radio"><input type="radio" name="r1" onChange={this.changePaymentMethod.bind(this, 'paypal')} checked={this.state.paymentMethod === 'paypal'} />
                        <span className="checkmark"></span>
                        Paypal
                      </label>*/}
                    </div>
                    {/* <div  className="grid">
                        {cardLogos.map((cardLogo, index) => (
                              <div>
                                <img  src={cardLogo} alt={`Card Logo ${index + 1}`} key={index}/>
                              </div>
                        ))}
                    </div> */}
                    <div className="row clearfix">
                      <div className="col-sm-6 form-group">
                        <label className="formLable">NAME ON CARD</label>
                        <input ref="cardHolder" required name="cardHolder" onBlur={this.handleCardBlur.bind(this)} value={this.state.card.cardHolder} onChange={this.handleCardChange.bind(this)} type="text"  className={'form-control' + (this.state.card_cardHolder_error ? ' error' : '')+" sentry-mask"} placeholder="Name On Card" />
                        <div className="error">{this.state.card_cardHolder_error}</div>
                      </div>
                      <div className="col-sm-6 form-group">
                        <label className="formLable">CREDIT CARD NUMBER</label>
                        <InputMask required maskChar='' mask="9999-9999-9999-9999" ref="number" onBlur={this.handleCardBlur.bind(this)} onChange={this.handleCardChange.bind(this)} value={this.state.card.number} name="number" className={'form-control' + (this.state.card_number_error ? ' error' : '') +" sentry-mask"} placeholder="Credit Card Number" />
                        <div className="error">{this.state.card_number_error}</div>
                      </div>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-3 form-group">
                        <label className="formLable">EXMONTH</label>
                        <select ref="month" required name="month" value={this.state.card.month} onBlur={this.handleCardBlur.bind(this)} onChange={this.handleCardChange.bind(this)}   className={'form-control' + (this.state.card_month_error ? ' error' : '')+" sentry-mask"}>
                          <option value=''>MM</option>
                          {this.state.months.map((item, index) => {
                            return (
                              <option key={index}>{item}</option>
                            )
                          })}
                        </select>
                        <div className="error">{this.state.card_month_error}</div>
                      </div>
                      <div className="col-sm-3 form-group">
                        <label className="formLable">EXYEAR</label>
                        <select ref="year" required name="year" value={this.state.card.year} onBlur={this.handleCardBlur.bind(this)} onChange={this.handleCardChange.bind(this)}  className={'form-control' + (this.state.card_year_error ? ' error' : '')+" sentry-mask"}>
                          <option value=''>YY</option>
                          {this.state.years.map((item, index) => {
                            return (
                              <option key={index}>{item}</option>
                            )
                          })}
                        </select>
                        <div className="error">{this.state.card_year_error}</div>
                      </div>
                      <div className="col-sm-3 form-group">
                        <label className="formLable">SEC CODE</label>
                        <InputMask required maskChar='' mask="9999" ref="cvv" onBlur={this.handleCardBlur.bind(this)} onChange={this.handleCardChange.bind(this)} value={this.state.card.cvv} name="cvv"   className={'form-control' + (this.state.card_cvv_error ? ' error' : '')+" sentry-mask"} placeholder="SEC" />
                        <div className="error">{this.state.card_cvv_error}</div>
                      </div>
                      <div className="col-sm-3 form-group">
                        <label className="formLable">ZIP CODE</label>
                        <input inputMode="numeric" pattern="(\d{5}([\-]\d{4})?)" ref="zip" required name="zip" value={this.state.card.zip} onBlur={this.handleCardBlur.bind(this)} onChange={this.handleCardChange.bind(this)} type="text"  className={'form-control' + (this.state.card_zip_error ? ' error' : '')+" sentry-mask"} maxLength={5} placeholder="ZIP" />
                        <div className="error">{this.state.card_zip_error}</div>
                      </div>
                    </div>
                  </div>}
                  <div className="form-group clearfix">
                    <label className="createAccount checkbox"><input type="checkbox" value={this.state.acceptTerms} checked={this.state.acceptTerms} onChange={this.handleTermsAccept.bind(this)} /> <span className="checkmark"></span> <span className='terms'>I have read and accept the <Link to="/terms-and-conditions" target="_blank" externalLink={true} className="bold">Tripper Bus Terms and Conditions</Link>. Tickets are non refundable. Click <a href={process.env.REACT_APP_HOME_DOMAIN_URL + '/terms-and-conditions'} target="_blank">here</a> for details</span>
                    </label>
                    {this.state.submitted && !this.state.loading && !this.state.error && !this.state.departTripCode && this.state.acceptTerms && !this.validateData(true) && <div className="error">There are errors in your checkout form, please scroll up to review</div>}
                    {this.state.submitted && !this.state.acceptTerms && <div className="error">Please read and accept the terms and conditions.</div>}
                    {this.state.error && <div className="error server-error">{this.state.error}</div>}
                    {this.state.departTripCode && !this.state.returnTripCode && this.state.confirmationCode && <div className="success">Your reservation is confirmed. Please note down your trip reservation number {config.formatReservationNumber(this.state.departTripCode)}. You can use this confirmation code to login to your account {this.state.confirmationCode}</div>}
                    {this.state.departTripCode && this.state.returnTripCode && this.state.confirmationCode && <div className="success">Your reservation is confirmed. Please note down your trip reservation numbers {config.formatReservationNumber(this.state.departTripCode)} and {config.formatReservationNumber(this.state.returnTripCode)}. You can use this confirmation code to login to your account {this.state.confirmationCode}</div>}
                    {this.state.departTripCode && !this.state.returnTripCode && !this.state.confirmationCode && <div className="success">Your reservation is confirmed. Please note down your trip reservation number {config.formatReservationNumber(this.state.departTripCode)}.</div>}
                    {this.state.departTripCode && this.state.returnTripCode && !this.state.confirmationCode && <div className="success">Your reservation is confirmed. Please note down your trip reservation numbers {config.formatReservationNumber(this.state.departTripCode)} and {config.formatReservationNumber(this.state.returnTripCode)}.</div>}
                  </div>

                  </div>}
                </div>
               {((this.state.user.id || this.state.continueAsGuest) && this.state.currentStep == 3 ) &&  <div className="form-group clearfix bottom-purchase-section">
                    <button disabled={this.state.loading} onClick={this.bookSeats.bind(this)} type="button" className="btn btn-blue">CONFIRM PURCHASE</button>
                  </div>}
              </div>

           

              <div className="col-md-4">
                  <div className="os order-summary only-desktop">
                          <div className='divider'></div>
                        <div className="grid head">
                          Summary
                        </div>
                        <div className="grid trip-summary">
                          <span><img src={departBusIcon} height="24px" width="24px" alt="depart bus icon"/></span>
                          <span>{this.props.getShortLabel(this.props.data.departCity)} to {this.props.getShortLabel(this.props.data.arriveCity)}</span>
                        </div>
                        <div className='grid'>
                            <div className="depart-trip-time">
                               <div>{this.props.data.departDate.format('MMM DD')}</div>
                               <div>{moment(this.props.data.depart_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                               <div></div>
                               <div>{moment(this.props.data.arrive_stop.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                            <div className="depart-trip-time-data">
                              <div>{this.props.data.departDate.format('dddd')}</div>
                              <div className='city'>{this.props.data.departCity}</div>
                              <div><img src={routeIcon} width="11px" height="10px" alt='route icon' /></div>
                              <div className='city'>{this.props.data.arriveCity}</div>
                            </div>
                            {/* <div><span>Approximate duration: 4hs (subject to traffic, weather and stop locations)</span></div> */}
                        </div>


                        <div className='grid price-summary border-bottom'>
                        <span><img src={personIcon} height="24px" width="24px" alt="person icon"/></span>
                          <div>{this.getNumberOfSeats()} {this.props.data.departTrip.bus_type.type === 'Elite' &&<img src={eliteIcon} height="20px" alt="elite bus icon"/>}</div>
                          <div>{numeral(this.props.data.departTrip.price*this.props.data.seats).format('$0,0.00')}</div>
                        </div>


                        {this.props.data.returnTrip ?
                        <div>
                        <div className="grid trip-summary">
                        <span><img src={returnBusIcon} height="24px" width="24px" alt="return bus icon"/></span>
                          <span>{this.props.getShortLabel(this.props.data.returnDepartCity)} to {this.props.getShortLabel(this.props.data.returnArriveCity)}</span>
                        </div>
                        <div className='grid'>
                            <div className="depart-trip-time">
                               <div>{this.props.data.returnDate.format('MMM DD')}</div>
                               <div>{moment(this.props.data.depart_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                               <div></div>
                               <div>{moment(this.props.data.arrive_stop1.time, 'HH:mm:ss').format('h:mm a')}</div>
                            </div>
                            <div className="depart-trip-time-data">
                              <div>{this.props.data.returnDate.format('dddd')}</div>
                              <div className='city'>{this.props.data.returnDepartCity}</div>
                              <div><img src={routeIcon} width="11px" height="10px" alt='route icon' /></div>
                              <div className='city'>{this.props.data.returnArriveCity}</div>
                            </div>
                            {/* <div><span>Approximate duration: 4hs (subject to traffic, weather and stop locations)</span></div> */}
                        </div>


                      <div className='grid price-summary border-bottom'>
                        <span><img src={personIcon} height="24px" width="24px" alt="person icon"/></span>
                          <div>{this.getNumberOfSeats()} {this.props.data.returnTrip.bus_type.type === 'Elite' && <img src={eliteIcon} height="20px" alt="elite bus icon"/>}</div>
                          <div>{numeral(this.props.data.returnTrip.price*this.props.data.seats).format('$0,0.00')}</div>
                        </div>
                        </div>: null
                      }


                        <div className='grid price-summary'>
                        <span><img src={reservationFeeIcon} height="24px" width="24px" alt="reservation fee icon"/></span>
                          <div>Reservation Fee</div>
                          <div>{this.state.reservationFee && numeral(this.state.reservationFee).format('$0,0.00')}</div>
                        </div>
                        <div className='grid price-summary'>
                        <span><img src={fuelFeeIcon} height="24px" width="24px" alt="fuel fee icon"/></span>
                          <div>Fuel Surcharge</div>
                          <div>{this.state.fuelSurcharge && numeral(this.state.fuelSurcharge).format('$0,0.00')}</div>
                        </div>

                        
                        <div className='grid price-summary'>
                        {/* <span><img src={fuelFeeIcon} height="24px" width="24px"/></span> */}
                          <div>Subtotal</div>
                          <div className='only-desktop'></div>
                          <div>{numeral(this.getSubtotal()).format('$0,0.00')}</div>
                        </div>

                        <div>
                        {this.props.isEditing && this.state.discount != 0 &&
                          <div className='grid price-summary'>
                            <div className='discount'>Original Ticket:</div>
                            <div className='only-desktop'></div>
                            <div className='discount'>{'- '}${this.props.data.amount}</div>
                          </div>
                        }

                            {this.state.discount != 0 && this.showDiscountSection()}
                        </div>
                        <div className='mobile-divider'>

                        </div>
                        <div className='grid final-summary'>
                          <div>
                            Total
                          </div>
                          <div>
                              ${numeral(this.state.payment).format('0,0.00')}
                          </div>
                        </div>
                  </div>
                </div>
            </div>
          </div>

        </div>

    )
  }
}

export default ConfirmTrip;
