import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ReservationService from '../../services/reservation';
import moment from 'moment';
import personIcon from '../../img/new_person_icon.svg';
import _ from 'lodash';
import config from '../../config';
import {history as browserHistory} from '../../helpers/history';
import { eventTracker } from '../../helpers/ga_events';

class Reservation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cities: config.cities,
      loading: false,
      error: '',
      success: '',
      count: 1,
      seats: []
    };
  }



  getStopLabel(stop) {
    return _.find(this.state.cities, {value: stop}).label;
  }

  getStopMetro(stop) {
    return _.find(this.state.cities, {value: stop}).metro;
  }

  getStopPickup(stop) {
    return _.find(this.state.cities, {value: stop}).address;
  }

  getTime(time) {
    return moment(time, "HH:mm:ss").format("hh:mm A");
  }

  handleCount(e) {
    this.setState({count: e.target.value});
  }

  setSeatsInDropDown() {
    let seats = [];
    for(let i = 1; i <= this.props.reservation.reservations.length; i++) {
      seats.push(i);
    }
    this.setState({seats: seats, count: 1});
  }

  findReservation() {
    this.setState({loading: true, error: ''});
    let criteria = {
      email: this.props.reservation.email,
      reservationNumber: this.props.reservation.reservationNumber
    };
    ReservationService.getByEmail(criteria)
    .then(() => {
      this.setSeatsInDropDown();
    })
    .catch((err) => {
      this.setState({loading: false, error: err.response.statusText});
    });
  }

  proceedToCancel() {
    this.setState({loading: true, error: '', success: ''});
    let reason = 'User downgraded ticket';
    ReservationService.cancelSeats(this.props.reservation.reservationNumber, this.props.reservation.email, this.state.count, reason, this.props.reservation.reservationGroup)
    .then((result) => {
      eventTracker('cancel_ticket', 'click', 'Reservations');
      this.props.clearReservation(this.props.reservation.email);
      //this.setState({success: 'Selected seats cancelled and credit has been added to your account.'});
      //this.findReservation();
      this.props.setRefund(result.refund);
    })
    .catch((err) => {
      let error = err.response.statusText;
      if(err && err.response && err.response.text) {
        error = err.response.text;
      }
      this.setState({loading: false, error: error});
    });
  }

  rescheduleSeats() {
    let amount = 0, points = 0;
    for(let i = 0; i < this.state.count; i++) {
      amount += (this.props.reservation.reservations[i].credit || 0) + (this.props.reservation.reservations[i].paid || 0);
      points += (this.props.reservation.reservations[i].vouchers || 0);
    }
    this.props.clearReservation(this.props.reservation.email);
    browserHistory.push('/?reservationNumber='+this.props.reservation.reservationNumber+'&busType='+this.props.reservation.bus_type+'&seats='+this.state.count + '&departCity=' + this.props.reservation.reservations[0].depart_stop.city + '&arriveCity=' + this.props.reservation.reservations[0].arrive_stop.city + '&amount=' + amount + '&points=' + points + '&price=' + this.props.reservation.reservations[0].order_item.amount);
  }

  cancelSeats(e) {
    confirmAlert({
      title: 'Confirm to cancel',
      message: 'Are you sure to do this?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.proceedToCancel()
        },
        {
          label: 'No',
          onClick: () => ''
        }
      ]
    });
  }

  componentDidMount() {
    this.setSeatsInDropDown();
  }

  render() {
  	return (
  		this.props.reservation && <div className="container editTrip confirmTrip">
        <div className="editTripBox">
          <div className="header-content">
            <h4>We have found your reservation!</h4>
            <p>Reservation #{config.formatReservationNumber(this.props.reservation.reservationNumber)}</p>
          </div>
          <div>
            <p>You currently have <span className="bold">{this.props.reservation.reservations.length}</span> ticket/s. Select how many you'd like to edit/cancel.</p>
            <div className="row tripDetails">
              <div className="col-sm-6 departingTrip">
                <p className="tripType">Reservation Details:</p>
                <p className="tripAddress">{this.getStopLabel(this.props.reservation.reservations[0].depart_stop.city)} to {this.getStopLabel(this.props.reservation.reservations[0].arrive_stop.city)}</p>
                <p>{moment(this.props.reservation.reservations[0].slot, "YYYY-MM-DD").format('MMM D, YYYY')} at <span className="bold">{moment(this.props.reservation.reservations[0].depart_stop.time, 'HH:mm:ss').format('h:mm a')}</span></p>
                <div className="pickUp">
                  <p>Pick up:</p>
                  <p className="gray-text">{this.props.reservation.reservations[0].depart_stop.address}</p>
                  <p className="description">{this.props.reservation.reservations[0].depart_stop.description}</p>
                </div>
                <div className="dropOff">
                  <p>Drop off:</p>
                  <p className="gray-text">{this.props.reservation.reservations[0].arrive_stop.address}</p>
                  <p className="description">{this.props.reservation.reservations[0].arrive_stop.description}</p>

                </div>
              </div>
              <div className="col-sm-6 callUs">
                <p>If you have questions</p>
                <h5>Call us at  <a href="tel:+17188349214">718-834-9214</a></h5>
              </div>
            </div>
            <div className="ticketCancelChangeBox">
              <p>HOW MANY TICKETS DO YOU WANT TO CHANGE/CANCEL</p>
              <div className="row">
                <div className="col-md-2 form-group">
                  <img src={personIcon} alt="person icon" className="chairIcon" width="18px" height="18px" />
                  <select onChange={this.handleCount.bind(this)} name="count" value={this.state.count} className="form-control" id="inlineFormInputGroup">
                    {this.state.seats.map((item, index) => {
                      return (
                        <option key={index}>{item}</option>
                      )
                    })}
                  </select>
                </div>
                <div className="col-md-5 form-group">
                  <button type="button" className="btn btn-block btn-blue cancel-btn" disabled={!this.state.count || this.state.loading} onClick={this.cancelSeats.bind(this)}>CANCEL</button>
                </div>
                <div className="col-md-5 form-group">
                  <button type="button" className="btn btn-block btn-blue" disabled={!this.state.count || this.state.loading} onClick={this.rescheduleSeats.bind(this)}>RESCHEDULE</button>
                </div>
              </div>
              {this.state.error && <div className="error">{this.state.error}</div>}
              {this.state.success && <div className="sucess">{this.state.success}</div>}
            </div>

          </div>
        </div>
      </div>
  	)
  }
}

export default Reservation;

